import type { FC } from "react";
import { Navigate } from "react-router-dom";
import { NamedRoute } from "../components/utils/NamedRoutes";
import AuthLayout from "../common/layout/AuthLayout";
import Button from "../components/ui/button/Button";
import { useLDFlags } from "../hooks/use-ld-flags";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthLanding: FC = () => {
	const { auth0Login } = useLDFlags();
	const { loginWithRedirect } = useAuth0();

    if (!auth0Login) {
        return <Navigate to={NamedRoute.login} />
    }

	return (
        <AuthLayout>
            <div className="flex flex-row h-full w-full">
                <div className="flex flex-col items-center md:items-start justify-center h-full w-full lg:w-1/2">
                    <div className="flex flex-col w-full max-w-[90%] md:max-w-[50%] gap-6 px-20 md:px-0">
                        <h1 className="text-6xl font-normal">Welcome!</h1>
                        <span>Please login to continue your application</span>
                        <Button
                            text="Log in"
                            variant="primary"
                            iconSuffix={<i className="icon-arrow" />}
                            handleClick={() => loginWithRedirect()}
                        />
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
