import { Box } from "./Box";
import Button from "../../../../ui/button/Button";
import { Status } from "../../../../ui/status/Status";
import GenericInput from "../../../generic-input/GenericInput";
import { type UseJointBorrowerResendOptions, useJointBorrowerResend } from "../../../../../hooks/origination/use-joint-borrower-resend";

export interface BoxJointBorrowerProps extends UseJointBorrowerResendOptions {
	isAccepted: boolean
}

export const BoxJointBorrower = (props: BoxJointBorrowerProps) => {
	const { applicant, isAccepted } = props;
    const { register, handleSubmit, isDirty, onSubmit, validationErrorMessage, isEditing, onToggleEdit, inviteJointApplicant } = useJointBorrowerResend(props);
	return (
		<Box className="flex flex-col gap-3">
			<div className="flex flex-row justify-between">
				<div className="text-lg"><i className="icon-user" />&nbsp;Joint Applicant</div>
				<Status status={isAccepted ? "active" : "required"} text={isAccepted ? "Accepted" : "Yet to accept"} icon={<i className={isAccepted ? "icon-check" : "icon-warning"} />} />
			</div>
			{
				isAccepted ? undefined : (
					<>
						<div className="p-4 bg-peach-60 text-black-100">
							Note: You will be unable to successfully lodge your application without your Joint Applicant accepting their invitation and completing the verification
						</div>
						<div className={`flex flex-col justify-start divide-y devide-solid divide-black-10 my-4 border-t border-b ${isEditing ? "border-b-slate-500" : ""}`}>
							<div className="flex flex-row justify-between items-center py-4">
								<div>
									<i className="icon-warning text-peach-100" />&nbsp;{applicant.firstName} {applicant.lastName}
								</div>
							</div>
							<div className="flex flex-row justify-between items-center py-4">
								<div className={`flex flex-row items-center ${isEditing ? "font-medium" : "tracking-wide"}`}>
									<i className="icon-mobile" />&nbsp;
									<GenericInput
										register={register} 
										type="text" 
										disabled={!isEditing} 
										inputContainerClassName="border-none pb-0"
										inputClassName="text-off-black-900"
										name="jointBorrowerPhone"
										placeholder="Joint Applicant Phone"
										noBorder
									/>
								</div>
								<div className={`grow flex flex-row items-center ${isEditing ? "font-medium" : ""}`}>
									<i className="icon-email" />&nbsp;
									<GenericInput 
										register={register}
										type="email" 
										disabled={!isEditing} 
										className="flex-grow" 
										inputContainerClassName="border-none p-0"
										inputClassName="text-off-black-900"
										name="jointBorrowerEmail"
										placeholder="Joint Applicant Email Address"
										noBorder
									/>
								</div>
								{
									isEditing ? undefined : (
										<Button
											text="Edit"
											className="min-w-min rounded"
											iconPrefix={<i className="icon-edit" />}
											variant="link"
											textAlign="center"
											handleClick={onToggleEdit}
										/>
									)
								}
							</div>
						</div>
						{
							inviteJointApplicant.error || (isEditing && validationErrorMessage) ? (
								<div className="p-4 bg-peach-60 text-red-500">
									<i className="icon-warning" />&nbsp;{(isEditing && validationErrorMessage) || String(inviteJointApplicant.error?.message)}
								</div>
							) : undefined
						}
						<div className="flex flex-row justify-between items-center">
							{
								inviteJointApplicant.isSuccess ? (
									<span className="text-lg">
										Invite sent!
									</span>
								) : (
									(isEditing || inviteJointApplicant.isPending) ? <span /> : (<span/>
										// <Button
										// 	text="Talk to a consultant"
										// 	className="min-w-min text-lg rounded"
										// 	iconSuffix={<i className="icon-message" />}
										// 	variant="accent"
										// 	textAlign="center"
										// 	handleClick={() => {
										// 		// TODO add link to open chat
										// 		console.log("Talk to a consultant");
										// 	}}
										// />
									)
								)
							}
							<Button
								text="Resend Invite"
								className="min-w-min text-lg rounded"
								iconSuffix={<i className="icon-send" />}
								variant="primary"
								textAlign="center"
								isDisabled={Boolean(validationErrorMessage) || inviteJointApplicant.isPending || (!isDirty && inviteJointApplicant.isSuccess)}
								handleClick={handleSubmit(onSubmit)}
							/>
						</div>
					</>
				)
			}
		</Box>
	)
};