import { useMemo } from "react";
import { ApplicationStatus } from "@sucasa-finance/origination-trpc";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { selectApplicationStatus, selectWorkingTowardsStatus } from "../../../../store/slices/origination-slice";

export const WorkingTowards = () => {
	const applicationStatus = useAppSelector(selectApplicationStatus);
	const workingTowardsStatus = useAppSelector(selectWorkingTowardsStatus);

	const statusText = useMemo(() => {
		if (applicationStatus === ApplicationStatus.PreApproved) {
			return {
				action: "Apply for",
				status: "Unconditional Approval",
			}
		}
		if (workingTowardsStatus === ApplicationStatus.WorkingTowardsUnconditionalApproval) {
			return {
				action: "You're currently working towards",
				status: "Unconditional Approval",
			}
		}
		return {
			action: "You're currently working towards",
			status: "Pre-Approval",
		}
	},[applicationStatus, workingTowardsStatus])

	return (<>
		{applicationStatus !== ApplicationStatus.UnconditionallyApproved &&
			<div className="flex flex-row gap-2 content-center items-center w-0 lg:w-auto invisible lg:visible">
				<span className="text-sm">{statusText.action}:</span>
				<span className="flex flex-row w-5 h-5 p-0 m-0 rounded-full border border-off-black-600">
				<span className="w-5 h-5 rounded-full border-t border-off-black-900 rotate-45" />
			</span>
				<span>{statusText.status}</span>
			</div>}
	</>)
};
