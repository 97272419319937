import { type FC, useCallback, useMemo } from "react";
import banner from "../../../../assets/welcome-back.jpg";
import { ApplicationStatus, ApplicationStep, ApplicationSubStep, ApplicationUserRole } from "@sucasa-finance/origination-trpc";
import { useNavigate } from "react-router-dom";
import { NamedRoute } from "../../../utils/NamedRoutes";
import { WbStepperNew } from "./components/WbStepperNew";
import WelcomeLoanItem from "../../../ui/welcome-loan-item/WelcomeLoanItem";
import { Status } from "../../../ui/status/Status";
import Button from "../../../ui/button/Button";
import { wbAllSteps, wbJointSteps } from "../wb-stepper/WbStepper.config";
import { WbProgress } from "./components/WbProgress";
import { formatPrice } from "../../../../helpers/format-price";
import { formatDate } from "../../../../helpers/format-date";
import { isApplicationActive } from "../../../../helpers/is-application-active";
import type { User, UserApplication } from "../../../../services/apis/origination/types";
import { getUserNameText } from "../../../../helpers/get-user-name-text";
import { Progress } from "../../../ui/progress/Progress";
import { useWbSteps } from "../../../../hooks/use-wb-steps";
import { useTracking } from "../../../../hooks/use-tracking";
import { identifyApplication } from "../../../../services/analytics";

type TWbLoanDetailsNewProps = {
	application: UserApplication
}
export const WbLoanDetailsNew: FC<TWbLoanDetailsNewProps> = ({
	application
}) => {
	const navigate = useNavigate();
	const {trackEvent} = useTracking();
	const isActive = useMemo(() => isApplicationActive(application.status),
		[application.status]);
	const otherApplicants = useMemo(() => application.applicants.filter(itm => itm.userId !== application.currentUserId)
			.map(itm => ({
				name: getUserNameText(itm as User),
				currentStep: itm.applicationSteps.length,
				totalSteps: itm.role === ApplicationUserRole.Primary ? wbAllSteps.length : wbJointSteps.length,
			})),
		[application.applicants, application.currentUserId]);
	const currentApplicant = useMemo(() => application.applicants.find(itm =>
			itm.userId === application.currentUserId),
		[application.applicants, application.currentUserId]);
	// const primaryApplicant = useMemo(() => application.applicants.find(itm =>
	// 		itm.role === ApplicationUserRole.Primary),
	// 	[application.applicants]);

	const currentApplicantStep = currentApplicant?.applicationSteps?.[0]?.step || ApplicationStep.Details;
	const currentApplicantSubStep = currentApplicant?.applicationSteps?.[0]?.subStep || ApplicationSubStep.Invited;

	const { steps, currentStepIndex } = useWbSteps({ isJointApplicant: application.isJointApplicant, applicationStep: currentApplicantStep });

	const continueWithApplication = useCallback(() => {
		trackEvent("resume_application_clicked", {
			applicationId: application.id,
			version: '2',
		});
		identifyApplication({
			appId: application?.id.toString() ?? '',
			applicantType: currentApplicant?.role === ApplicationUserRole.Primary ? 'Primary Applicant' : 'Secondary Applicant',
			applicationVersion: '2',
			applicationType: application?.borrowerType ?? '',
		})
		const isNewUser = currentApplicantStep === ApplicationStep.Details || currentApplicantSubStep === ApplicationSubStep.Invited || currentApplicantSubStep === ApplicationSubStep.Accepted;

		if (application.applicationStatus === ApplicationStatus.Declined) {
			navigate(NamedRoute.notAGoodFit);
			return;
		}
		if (currentApplicantStep === ApplicationStep.Submitted) {
			navigate(NamedRoute.verification, {state: {applicationId: application.id}});
		} else if (application.isJointApplicant && isNewUser) {
			navigate(NamedRoute.jointBorrowerIntro, {state: {applicationId: application.id}});
		} else if (application.isJointApplicant) {
			navigate(NamedRoute.jointBorrowerHome, {state: {applicationId: application.id}});
		} else {
			navigate(NamedRoute.home, {state: {applicationId: application.id}});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [application.id, application.isJointApplicant, currentApplicantStep, currentApplicantSubStep, navigate])

	return (
		<section className={`flex flex-col lg:flex-row ${!isActive && 'opacity-40'} rounded mb:rounded-none overflow-hidden`}>
			<div
				className="card-bg md:min-h-56 bg-off-black-400 relative overflow-hidden flex flex-col justify-between before:right-0 before:left-0 before:top-0 before:bottom-0 before:absolute before:z-10 before:bg-no-repeat bg-gradient-to-r from-off-black-900 from-0% to-off-black-400 to-100% lg:w-3/5">
				<img
					src={banner}
					alt="banner"
					className="absolute top-0 left-0 right-0 bottom-0 object-cover w-full h-full"
				/>
				<div className="flex flex-row h-full relative z-10">
					{isActive && <div className="w-1/3 hidden md:flex">
						<WbStepperNew
							isJointApplicant={application.isJointApplicant}
							applicationStep={currentApplicantStep}
						/>
					</div>}
					<div className={`${isActive ? 'w-full md:w-2/3' : 'w-full'} flex flex-col justify-end px-6 py-2 md:p-0`}>
						<div className="flex flex-row justify-between items-start border-b-[1px] border-b-off-black-600 md:border-none">
							<h2 className="py-4 md:px-8 text-white text-2xl md:text-4xl font-normal md:font-medium">
								{application.property}
							</h2>
							<div className="my-4 md:hidden">
								<Status status={isActive ? 'active' : "inactive"} />
							</div>
						</div>
						<div
							aria-labelledby="loan details"
							className="grid grid-cols-2 md:flex justify-between flex-wrap gap-6 md:gap-0 py-6 md:py-0 md:pt-4 md:pb-5 md:px-8 w-3/5 md:w-full"
						>
							{application.createdAt && (
								<WelcomeLoanItem label="Date started" value={formatDate(application.createdAt)} />
							)}
							<WelcomeLoanItem label={<>
								<span className="hidden md:inline">Reference number</span>
								<span className="md:hidden">Ref no.</span>
							</>} value={String(application.id)} />
							<WelcomeLoanItem
								label="Property value"
								value={formatPrice(application.purchasePrice)}
							/>
							<WelcomeLoanItem
								label="Loan amount"
								value={formatPrice(application.borrowAmount)}
							/>
						</div>
					</div>
				</div>
				<Progress value={currentStepIndex + 1} max={steps.length} className="md:hidden bg-transparent z-20" />
			</div>
			<div className="flex flex-col justify-between bg-white lg:w-2/5 p-4 md:p-8">
				<div className="hidden md:flex justify-between items-center md:justify-end flex-wrap">
					<div className="flex md:hidden">
						<Button
							className="rounded"
							text="Resume application"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							handleClick={continueWithApplication}
						/>
					</div>
					<div className="my-4 md:my-0">
						<Status status={isActive ? 'active' : "inactive"} />
					</div>
				</div>
				<div className="border-b-[1px] border-b-off-black-200 md:hidden">
					<WbProgress
						applicationStep={currentApplicantStep}
						isJointApplicant={application.isJointApplicant}
					/>
				</div>
				{otherApplicants.length > 0 && <div className="py-4 md:border-b border-off-black-400">
					<div className="md:pb-2 text-sm text-off-black-600 md:text-xl md:text-primary">Joint Applicant</div>
					{otherApplicants.map((applicant, index) => (<div key={index} className="flex flex-row justify-between">
						<div className="text-sm flex flex-row">
							<i className="icon-user-1" />&nbsp;<span className="inline-block">{applicant.name}</span>
						</div>
						<div className="text-sm flex flex-row">
							<i className="icon-progress" />&nbsp;<span className="text-end">{applicant.currentStep < applicant.totalSteps ? `Step ${applicant.currentStep} of ${applicant.totalSteps}` : 'Completed'}</span>
						</div>
					</div>))}
				</div>}
				<div>
					<div className="hidden md:block">
						<WbProgress
							applicationStep={currentApplicantStep}
							isJointApplicant={application.isJointApplicant}
						/>
					</div>
					<div className="flex flex-row justify-end md:justify-between">
						<Button
							className="rounded"
							text="Resume application"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							handleClick={continueWithApplication}
						/>
						{/* <Button
							className="rounded"
							text="Need Help?"
							variant="accent"
							iconSuffix={<i className="icon-message" />}
						/> */}
					</div>
				</div>
			</div>
		</section>
	);
};
