import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FormEvent } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import type { OptionsType } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setFirstName,
	setMiddleName,
	setSurname,
	setTitle,
} from "../../../../../store/slices/form-slice";
import {
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import Button from "../../../../ui/button/Button";
import GenericInput from "../../../generic-input/GenericInput";
import SelectInput from "../../../select-input/SelectInput";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import {

	SubStep,
} from "../../../../../services/apis/create-application.schema";

import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";

const titleOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Mr",
		value: "Mr.",
	},
	{
		id: 2,
		label: "Mrs",
		value: "Mrs.",
	},
	{
		id: 3,
		label: "Miss",
		value: "miss",
	},
	{
		id: 4,
		label: "Ms",
		value: "Ms.",
	},
	{
		id: 5,
		label: "Dr",
		value: "Dr.",
	},
	{
		id: 6,
		label: "Prof",
		value: "Prof.",
	},
	{
		id: 7,
		label: "Other",
		value: "other",
	},
];

const schema = z.object({
	firstName: z.string().min(1, "First name is required"),
	middleName: z.string().optional(),
	surname: z.string().min(1, "Surname is required"),
	title: z.object({
		id: z.number().min(1, "Please select an option"),
		label: z.string(),
		value: z.string(),
	}),
	noMiddleName: z.boolean(),
});

type TellUsAboutYouFormType = z.infer<typeof schema>;

type InitialFormType = {
	firstName: string;
	middleName: string;
	surname: string;
	title?: {
		id: number;
		label: string;
		value: string;
	};
};

const TellUsAboutYou = () => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);
	const { firstName, middleName, surname, title } = formState;
	const TellUsAboutYouDefaultValues: InitialFormType = {
		firstName,
		middleName,
		surname,
		title,
	};

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isValid, isDirty },
	} = useForm<TellUsAboutYouFormType>({
		resolver: zodResolver(schema),
		defaultValues: { ...TellUsAboutYouDefaultValues },
	});
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Tell us about you",
	});
	const showQuestion = watch();

	const saveData: SubmitHandler<TellUsAboutYouFormType> = (
		data: TellUsAboutYouFormType
	) => {
		dispatch(setFirstName(data.firstName));
		dispatch(setMiddleName(data.middleName!));
		dispatch(setSurname(data.surname));
		dispatch(setTitle(data.title));
		dispatch(setDynamicHints([]));
		dispatch(
			navigateToStep({
				step: "detailsStep",
				subStep: SubStep.YouAndYourHousehold,
			})
		);
	};


	register("noMiddleName", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.checked) {
				setValue("middleName", "");
			}
		},
	});
	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Tell us about you
		</h1>
	);
	const content = (
		<>
			<div className="flex flex-col gap-9">
					<SelectInput
						name="title"
						value={
							showQuestion?.title || {
								id: 0,
								value: "",
								label: "Select an option",
							}
						}
						label="Title"
						options={titleOptions}
						register={register}
						error={errors.title?.message}
					/>
					<GenericInput
						name="firstName"
						register={register}
						type="text"
						label="First Name"
						placeholder="First name"
						error={errors.firstName?.message}
					/>
					{!showQuestion.noMiddleName && (
						<GenericInput
							name="middleName"
							register={register}
							type="text"
							label="Middle Name"
							placeholder="Middle Name"
						/>
					)}
					<div className="-mt-6">
						<CheckboxInput
							label="I don't have a middle name"
							name="noMiddleName"
							checked={false}
							register={register}
							size="small"
						/>
					</div>
					<GenericInput
						name="surname"
						register={register}
						type="text"
						label="Surname"
						placeholder="Surname"
						error={errors.surname?.message}
					/>
			</div>
		</>
	);
	const footer = (
		<div className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					dispatch(setDynamicHints([]));
					dispatch(
						navigateToStep({
							step: "detailsStep",
							subStep: SubStep.ApplicationType,
						})
					);
				}}
			/>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={!isValid}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default TellUsAboutYou;
