import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setAddressHistory,
	setTwoYearsAddressHistoryForm,
	removeAddressInput,
} from "../../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";

import Button from "../../../../ui/button/Button";
import {
	type AddressHistoryFormSlice,
	type TwoYearsAddressHistoryFormSlice,
	type TwoYearsAddressHistoryFormType,
	TwoYearsAddressHistorySchema,
	SelectTwoYearsAddressHistoryForm,
} from "./TwoYearsAddressHistorySchema";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressHistoryDisplay from "./address-history-display/AddressHistoryDisplay";
import { sumYearsMonths } from "../../../../utils/helpers";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { selectUpdateDetailsPayload } from "../../../../../store/selectors/update-details-payload";
import {
	ApplicationType,
	Step,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { AddressHistoryHint } from "../../../../ui/hint/AddressHistoryHint";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateApplicantDetails } from "../../../../../services/apis/origination/application";
import { captureException } from "@sentry/react";

const emptyAddressHistory = {} as AddressHistoryFormSlice;
const TwoYearsAddressHistory = ({ isJoint }: { isJoint?: boolean }) => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(SelectTwoYearsAddressHistoryForm);
	const primaryBorrowerIsJointApplication =
		formState.applicationType === ApplicationType.Joint;
	const [updateDetails, { isLoading }] = useUpdateApplicantDetails();
	const updateDetailsPayload = useAppSelector(state => selectUpdateDetailsPayload(state)(isJoint ?? false));
	const {
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		watch,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		resolver: zodResolver(TwoYearsAddressHistorySchema),
		defaultValues: formState,
		mode: "all", // Ensure validation checks for all fields including custom validation
	});

	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Two Years Address History",
	});

	const totalMonths = sumYearsMonths(watch("addressHistory") ?? []);
	const percentage = (totalMonths / 24) * 100;

	const saveData = async (
		rawData: Partial<TwoYearsAddressHistoryFormSlice>
	) => {
		const data: TwoYearsAddressHistoryFormType =
			rawData as TwoYearsAddressHistoryFormType;
		dispatch(setAddressHistory(data.addressHistory));
		if (totalMonths >= 24) {
			try {
				await updateDetails({
					...updateDetailsPayload,
					livingSituation:
						data.addressHistory[0]?.currentLivingSituation?.value,
					addressHistory: formState.addressHistory?.map((address, index) => ({
						currentAddress: index === 0,
						previousAddress: index > 0,
						startDate: address.livingStartDate,
						endDate: address.livingEndDate,
						streetNumber: address.livingAddressParts?.streetNumber,
						streetName: address.livingAddressParts?.streetName,
						suburb: address.livingAddressParts?.suburb ?? "",
						state: address.livingAddressParts?.state ?? "",
						postalCode: address.livingAddressParts?.postalCode ?? "",
						...(address.livingAddressParts?.unitNumber && {
							unitNumber: address.livingAddressParts?.unitNumber,
						}),
						...(primaryBorrowerIsJointApplication
							? {
									applicationStep: Step.YourDetails,
									applicationSubStep: SubStep.JointApplicantInvite,
								}
							: {}),
					})),
				}).unwrap();

				dispatch(setTwoYearsAddressHistoryForm(SubStep.TwoYearsAddressHistory));
				if (isJoint) {
					dispatch(
						navigateToJointStep({
							step: "financeStep",
							subStep: SubStep.YourEmployment,
						})
					);
				} else {
					if (primaryBorrowerIsJointApplication) {
						dispatch(
							navigateToStep({
								step: "detailsStep",
								subStep: SubStep.JointApplicantInvite,
							})
						);
					} else {
						dispatch(
							navigateToStep({
								step: "financeStep",
								subStep: SubStep.YourEmployment,
							})
						);
					}
				}
			} catch (error) {
				captureException(new Error("Error adding address history"), {data: {error}});
				dispatch(
					setToast({
						open: true,
						type: "error",
						title: "Error",
						description: "An error occurred while adding your address history.",
					})
				);
			}
		}
	};

	const onChangeHandler = (
		addressHistory: Partial<AddressHistoryFormSlice>,
		index: number
	) => {
		const addressHistories = watch("addressHistory");
		if (addressHistories) {
			const updatedAddressHistory = [...addressHistories];
			updatedAddressHistory[index] = {
				...addressHistory,
				livingStartDate: addressHistory.livingStartDate ?? "",
				livingEndDate: addressHistory.livingEndDate ?? "",
			};
			dispatch(setAddressHistory(updatedAddressHistory));
			setValue("addressHistory", updatedAddressHistory);
			clearErrors(`addressHistory.${index}.livingAddress`);
			void trigger(`addressHistory.${index}.livingAddress`);
		}
	};
	const removeHandler = (index: number) => {
		const addressHistories = watch("addressHistory");
		if (addressHistories) {
			const updatedAddressHistory = [...addressHistories];
			updatedAddressHistory.splice(index, 1);
			dispatch(setAddressHistory(updatedAddressHistory));
			dispatch(removeAddressInput(`addressHistory-${index}`));
			setValue("addressHistory", updatedAddressHistory);
		}
	};
	useEffect(() => {
		if (!watch("addressHistory") || watch("addressHistory")?.length === 0) {
			setValue("addressHistory", [{ ...emptyAddressHistory }]);
		}
	}, [setValue, watch]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("AddressHistoryHint"));
		return () => {
			dispatch(removeDynamicHint("AddressHistoryHint"));
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	// Custom validation to ensure form is not valid without required address parts
	const isFormValid =
		isValid &&
		watch("addressHistory")?.every(
			(address) =>
				address.livingAddressParts?.streetNumber &&
				address.livingAddressParts?.streetName &&
				address.livingAddressParts?.suburb &&
				address.livingAddressParts?.state &&
				address.livingAddressParts?.postalCode
		);
	const header = (
		<h1 className="text-primary text-[28.43px] md:text-[37.9px] font-medium md:font-normal">
			Address History - 2 years
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			{isMobile && (
				<AddressHistoryHint percentage={percentage} totalMonths={totalMonths} />
			)}
			{watch("addressHistory")?.map((address, index) => (
				<AddressHistoryDisplay
					key={index}
					addressHistory={address}
					index={index}
					errors={errors}
					onChangeHandler={onChangeHandler}
					removeHandler={removeHandler}
					setError={setError}
					clearErrors={clearErrors}
				/>
			))}
			{totalMonths < 24 && (
				<Button
					text={
						<span>
							<i className="icon-plus" />
							Add a previous address
						</span>
					}
					type="button"
					size="full"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const addressHistories = watch("addressHistory");
						if (addressHistories) {
							const updatedAddressHistory = [
								...addressHistories,
								{ ...emptyAddressHistory },
							];
							setValue("addressHistory", updatedAddressHistory);
						}
					}}
				/>
			)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit" // Ensure button submits the form
				isDisabled={totalMonths < 24 || !isFormValid || isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default TwoYearsAddressHistory;
