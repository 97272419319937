import { type FC, useCallback, useMemo, useState } from "react";
import {
	ValidationStatus,
	VerificationCaseType,
	VerificationRequestType,
	VerificationResponseOwner,
	VerificationStatus,
} from "@sucasa-finance/origination-trpc";
import { Box } from "./Box";
import { Status, type TStatus } from "../../../../ui/status/Status";
import { ExpandingText } from "./ExpandingText";
import Button from "../../../../ui/button/Button";
import type { VerificationCase} from "../../../../../services/apis/origination/types";
import { FileUpload, type UploadCallbackParameters } from "./FileUpload";
import { useAddCaseResponse } from "../../../../../hooks/origination/use-add-case-response";
import { useTracking } from "../../../../../hooks/use-tracking";

type BoxUploadProps = {
	verificationCase?: VerificationCase
	onChatOpen?: (caseId?: number) => void;
	onBiometricsOpen?: (caseId?: number) => void;
}
export const BoxUpload: FC<BoxUploadProps> = ({
	verificationCase,
	onChatOpen,
	onBiometricsOpen,
}) => {
	const { isUploading, setIsUploading, onUpload } = useAddCaseResponse();
	const { trackEvent } = useTracking();

	const [warning, setWarning] = useState<string>();

	const uploadEnabled = useMemo(() =>
			verificationCase?.requestType === VerificationRequestType.Document
			&& verificationCase?.caseType !== VerificationCaseType.Biometrics,
		[verificationCase?.caseType, verificationCase?.requestType]);
	const isBiometrics = useMemo(() =>
			verificationCase?.caseType === VerificationCaseType.Biometrics,
		[verificationCase?.caseType]);
	const notifications = useMemo(() =>
			verificationCase?.caseResponses?.filter(itm =>
				itm.owner === VerificationResponseOwner.Admin && itm.validationStatus === ValidationStatus.Pending)?.length || 0,
		[verificationCase?.caseResponses]);
	const submittedFiles = useMemo(() => verificationCase?.caseResponses?.filter(itm =>
			itm.owner === VerificationResponseOwner.User && itm.fileName !== undefined)?.length || 0,
		[verificationCase?.caseResponses]);
	const submittedMessage = useMemo(() => {
			return submittedFiles > 0 && `${submittedFiles} ${submittedFiles === 1 ? 'file has' : 'files have'} been submitted`;
		},
		[submittedFiles]);
	const failedResponses = useMemo(() =>
			verificationCase?.caseResponses?.some(itm => itm.validationStatus === ValidationStatus.Fail),
		[verificationCase?.caseResponses]);

	const statusState: {
		status: TStatus;
		text: string;
		icon: string;
	} =  useMemo(() => {
		switch (verificationCase?.status) {
			case VerificationStatus.Waiting: {
				return {
					status: 'pending',
					text: 'Under review',
					icon: 'icon-progress',
				};
			}
			case VerificationStatus.Finished: {
				return {
					status: 'active',
					text: 'Complete',
					icon: 'icon-check',
				};
			}
			case VerificationStatus.Active: {
				if (failedResponses && uploadEnabled) {
					return {
						status: 'alert',
						text: 'Please re-upload',
						icon: 'icon-warning',
					};
				}
				if (failedResponses && !uploadEnabled && !isBiometrics) {
					return {
						status: 'alert',
						text: 'Please respond',
						icon: 'icon-message',
					};
				}
				if (uploadEnabled) {
					return {
						status: 'required',
						text: 'Please upload',
						icon: 'icon-upload',
					};
				}
				if (isBiometrics) {
					return {
						status: 'required',
						text: 'Please action',
						icon: 'icon-upload',
					};
				}
				return {
					status: 'required',
					text: 'Please respond',
					icon: 'icon-message',
				};
			}
			default: {
				if (uploadEnabled) {
					return {
						status: 'required',
						text: 'Please upload',
						icon: 'icon-upload',
					};
				}
				if (isBiometrics) {
					return {
						status: 'required',
						text: 'Please action',
						icon: 'icon-upload',
					};
				}
				return {
					status: 'required',
					text: 'Please respond',
					icon: 'icon-message',
				};
			}
		}
	}, [failedResponses, isBiometrics, uploadEnabled, verificationCase?.status]);

	const onUploadCallback = useCallback((uploadedFiles?: UploadCallbackParameters[]) => {
		trackEvent("documents_uploaded", {
			caseType: verificationCase?.caseType,
			status: verificationCase?.status,
			numberOfFiles: uploadedFiles?.length,
			fromWhere: "box_component",
		});
		const okFiles = uploadedFiles?.filter(itm => itm?.error === undefined);
		verificationCase?.id && onUpload(verificationCase.id, okFiles);
		const failedFiles = uploadedFiles
			?.filter(itm => itm?.error !== undefined)
			?.map(itm => `${itm.name} ${itm.error}`) ?? [];
		if (failedFiles.length > 0) {
			setWarning(failedFiles.join(', '))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verificationCase?.id, onUpload, verificationCase?.caseType, verificationCase?.status])

	return (<Box className="flex flex-col gap-3">
		<div className="flex flex-row justify-between">
			<div className="text-lg"><i className="icon-briefcase" /> {verificationCase?.subTitle} {verificationCase?.user?.firstName && `- ${verificationCase.user.firstName}`}</div>
			<Status status={statusState.status} text={statusState.text} icon={<i className={statusState.icon} />} />
		</div>
		{warning && <div className="flex flex-row flex-nowrap p-4 bg-red-brick-10 text-red-brick-100">
			<i className="icon-warning" /> {warning}
		</div>}
		{verificationCase?.status !== VerificationStatus.Finished && verificationCase?.description
			&& <ExpandingText text={verificationCase.description} caseType={verificationCase.caseType} status={verificationCase.status} />}
		{verificationCase?.status !== VerificationStatus.Finished && <>
			<div className="border-t border-off-black-400" />
			{!isUploading && <div className="flex flex-row gap-2 justify-between items-center">
				<Button
					text={<div className="relative block">
						<i className="icon-message text-xl" />
						{notifications > 0 && <div
							className="h-4 w-4 absolute top-0 right-0 rounded-full bg-red-brick-100 text-white text-xs">{notifications}</div>}
					</div>}
					className="min-w-min text-lg rounded"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						onChatOpen?.(verificationCase?.id);
					}}
				/>
				{submittedMessage && <span className="text-center">{submittedMessage}</span>}
				{isBiometrics && <Button
					text="Verify"
					className="min-w-min text-lg rounded"
					iconSuffix={<i className="icon-arrow" />}
					variant="primary"
					textAlign="center"
					handleClick={() => {
						onBiometricsOpen?.(verificationCase?.id);
					}}
				/>}
				{uploadEnabled && <Button
					text={submittedFiles > 0
						? <><span className="hidden md:block">Upload more</span><span className="block md:hidden">Upload</span></>
						: "Upload"}
					className="min-w-min text-lg rounded text-nowrap text-ellipsis"
					iconSuffix={<i className="icon-upload" />}
					variant="primary"
					textAlign="center"
					handleClick={() => {
						trackEvent("upload_clicked", {
							fromWhere: "box_component",
							caseType: verificationCase?.caseType,
							status: verificationCase?.status,
						});
						setIsUploading(true)
						// clear upload message on a new upload
						setWarning(undefined);
					}}
				/>}
			</div>}
			{verificationCase?.applicationId && isUploading && <FileUpload
				applicationId={verificationCase.applicationId}
				onUpload={onUploadCallback}
			/>}
		</>}
	</Box>)
};
