import { type FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";

import {
	setAdditionalBorrowingAmount,
	setAdditionalFundsReason,
	setLoanPreference,
	setLoanTerm,
	setLoanTermInYears,
	setMeetsNeedsAndObjectives,
	setRequestedLoanBuyingFeatures,
	setRequestedLoanBuyingFeaturesOther,
	setRequestedLoanRefinancingFeatures,
	setRequestedLoanRefinancingFeaturesOther,
	setSameBorrowersAsExistingLoan,
} from "../../../../../../store/slices/form-slice";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import Button from "../../../../../ui/button/Button";
import {
	LoanPreferenceOptions,
	sameBorrowersAsExistingLoanOptions,
} from "../../../../../utils/BootList";
import GenericInput from "../../../../generic-input/GenericInput";
import LoanTermFC from "../../buying-workflow/loan-term/LoanTerm";
import TotalSavings from "../../buying-workflow/total-savings/TotalSavings";

import { zodResolver } from "@hookform/resolvers/zod";
import {
	type LoanFeature,
	type ReasonForRefinancing,
	SubStep,
} from "../../../../../../services/apis/create-application.schema";
import {
	type NewHomeLoanFormSlice,
	type NewHomeLoanFormType,
	NewHomeLoanSchema,
	selectNewHomeLoanForm,
	selectPropertyValuation,
} from "./NewHomeLoanSchema";
import SelectInput from "../../../../select-input/SelectInput";
import RadioGroup from "../../../../radio-group/RadioGroup";
import FormLayout from "../../../../../ui/form/Form";

import { useMarkFormDirty } from "../../../../../../hooks/use-mark-form-dirty";

import Modal from "../../../../modal/Modal"; // Adjust the import path as necessary
import DollarInput from "../../../../dollar-input/DollarInput";
import useTrackPageViewOnMount from "../../../../../../hooks/use-track-on-mount";

const NewHomeLoan = () => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectNewHomeLoanForm);
	const propertyValuation = useAppSelector(selectPropertyValuation);
	const [lvrError, setLvrError] = useState<string | undefined>();

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm({
		resolver: zodResolver(NewHomeLoanSchema),
		defaultValues: formState,
	});
	const [showModal, setShowModal] = useState(false); // State to control modal visibility
	register("meetsNeedsAndObjectives", {
		onChange: (event: FormEvent<HTMLInputElement>) => {
			if (event.currentTarget.value === "false") {
				setShowModal(true);
			}
		},
	});
	const calculateTotalLoanAmount = () => {
		let totalAmount = 0;
		if (!formState.loansOnProperty) return 0;

		for (const loan of formState.loansOnProperty) {
			totalAmount += Number(loan.amount) || 0; // Ensure loan.amount is not undefined or null
		}
		return totalAmount;
	};
	useMarkFormDirty(isDirty);

	useTrackPageViewOnMount({
		page: "Your Loan",
		subPage: "Refinance - Your New Home Loan",
	});
	const formValues = watch();
	const totalMortgageAmount =
		calculateTotalLoanAmount() +
		Number(formValues?.additionalBorrowingAmount ?? 0);

	useEffect(() => {
		if (propertyValuation && totalMortgageAmount) {
			const lvr = (totalMortgageAmount / propertyValuation) * 100;
			const minimumLendAmount = propertyValuation * 0.85;
			const maximumLendAmount = propertyValuation * 0.98;
			const formattedPropertyValuation = propertyValuation.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}
			);
			const formattedMinimumLendAmount = minimumLendAmount.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}
			);
			const formattedMaximumLendAmount = maximumLendAmount.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}
			);
			if (lvr > 98 || lvr < 85) {
				setLvrError(
					`Your new mortgage amount must be between 85-98% of the property value. For your property value of $${formattedPropertyValuation} the new mortgage amount must therefore be between $${formattedMinimumLendAmount} - $${formattedMaximumLendAmount}.`
				);
			} else {
				setLvrError(undefined);
			}
		}
	}, [propertyValuation, totalMortgageAmount]);
	const saveData = async (rawData: Partial<NewHomeLoanFormSlice>) => {
		const data: NewHomeLoanFormType = rawData as NewHomeLoanFormType;
		dispatch(setAdditionalBorrowingAmount(data.additionalBorrowingAmount ?? 0));
		dispatch(setAdditionalFundsReason(data.additionalFundsReason ?? ""));
		dispatch(
			setSameBorrowersAsExistingLoan(data.sameBorrowersAsExistingLoan ?? false)
		);
		dispatch(setLoanPreference(data.loanPreference));
		dispatch(setLoanTerm(data.loanTerm ?? ""));
		dispatch(setLoanTermInYears(data.loanTermInYears as 30 | 25 | 20));
		dispatch(setMeetsNeedsAndObjectives(data.meetsNeedsAndObjectives ?? ""));
		dispatch(
			setRequestedLoanRefinancingFeatures(
				data.requestedLoanRefinancingFeatures.map(
					(feature) => feature as ReasonForRefinancing
				)
			)
		);
		dispatch(
			setRequestedLoanRefinancingFeaturesOther(
				data.requestedLoanRefinancingFeaturesOther ?? ""
			)
		);
		dispatch(setRequestedLoanBuyingFeatures(data.requestedLoanBuyingFeatures!));
		dispatch(
			setRequestedLoanBuyingFeaturesOther(
				data.requestedLoanBuyingFeaturesOther ?? ""
			)
		);

		dispatch(
			navigateToStep({
				step: "detailsStep",
				subStep: SubStep.ApplicationType,
			})
		);
	};

	const showSubmitButton =
		(formValues.meetsNeedsAndObjectives === "true" ||
			formValues.meetsNeedsAndObjectives === "false") &&
		!lvrError;

	register("loanTerm", {
		onChange(event: FormEvent<HTMLInputElement>) {
			if (event.currentTarget.value === "yes") {
				setValue("loanTermInYears", 30);
			}
			setValue("loanTerm", event.currentTarget.value);
		},
	});
	const onChangeLoanRefinancing = (checked: boolean, value: string) => {
		const label = value as ReasonForRefinancing;
		let updatedLoanRefinancingFeatures: Array<ReasonForRefinancing> = [];
		const requestedLoanRefinancingFeatures = watch(
			"requestedLoanRefinancingFeatures"
		);
		if (requestedLoanRefinancingFeatures) {
			updatedLoanRefinancingFeatures = [...requestedLoanRefinancingFeatures];
		}
		const indexToCheck = updatedLoanRefinancingFeatures.indexOf(label);
		if (checked && indexToCheck === -1) {
			updatedLoanRefinancingFeatures.push(label);
		} else {
			updatedLoanRefinancingFeatures.splice(indexToCheck, 1);
		}
		setValue(
			"requestedLoanRefinancingFeatures",
			updatedLoanRefinancingFeatures
		);
	};

	const onChangeLoanBuyingFeatures = (checked: boolean, value: string) => {
		const label = value as LoanFeature;
		let updatedLoanBuyingFeatures: Array<LoanFeature> = [];
		const requestedLoanBuyingFeatures = watch("requestedLoanBuyingFeatures");
		if (requestedLoanBuyingFeatures) {
			updatedLoanBuyingFeatures = [...requestedLoanBuyingFeatures];
		}
		const indexToCheck = updatedLoanBuyingFeatures.indexOf(label);
		if (checked && indexToCheck === -1) {
			updatedLoanBuyingFeatures.push(label);
		} else {
			updatedLoanBuyingFeatures.splice(indexToCheck, 1);
		}
		setValue("requestedLoanBuyingFeatures", updatedLoanBuyingFeatures);
	};
	const header = (
		<h1 className="text-primary text-[37.9px] font-normal">
			Your New Home Loan
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<DollarInput
				name="currentLoanBalance"
				control={control}
				type="numeric"
				value={calculateTotalLoanAmount()}
				label="Current loan balance is"
				iconPrefix={<i className="icon-dollar" />}
				placeholder="Amount"
				layout="secondary"
				disabled
			/>

			<DollarInput
				name="additionalBorrowingAmount"
				control={control}
				type="numeric"
				value={formState.additionalBorrowingAmount}
				error={
					errors.currentLoanBalance?.message ??
					errors.additionalBorrowingAmount?.message
				}
				label="Additional Borrowing Amount"
				iconPrefix={<i className="icon-dollar" />}
				placeholder="Amount"
				onValueChange={(value) => {
					// @ts-expect-error TODO(janyk): fix this
					setValue("additionalBorrowingAmount", value);
				}}
				layout="secondary"
				min={0}
			/>
			<TotalSavings
				label="New Mortgage Amount"
				value={totalMortgageAmount}
				errorMessage={lvrError}
			/>

			{formValues.additionalBorrowingAmount > 0 && !lvrError && (
				<GenericInput
					name="additionalFundsReason"
					register={register}
					type="text"
					label="What are you looking to use these additional funds for?"
					placeholder="e.g. Renovations, Debt Consolidation"
					error={
						errors.additionalFundsReason && errors.additionalFundsReason.message
					}
					maxLength={99}
				/>
			)}
			<RadioGroup
				register={register}
				radioOptions={sameBorrowersAsExistingLoanOptions}
				legend="Will your new loan have the same borrowers as the existing loan?"
				error={
					errors.sameBorrowersAsExistingLoan &&
					errors.sameBorrowersAsExistingLoan.message
				}
			/>

			{formValues.sameBorrowersAsExistingLoan !== undefined && (
				<div aria-labelledby="loan-preference">
					<h2 className="text-primary text-[37.9px] font-normal">
						Loan Preference
					</h2>
					<SelectInput
						name="loanPreference"
						value={
							watch("loanPreference") || {
								id: 0,
								value: "",
								label: "Select an option",
							}
						}
						label="Which is most important for you and your financial situation?"
						options={LoanPreferenceOptions}
						register={register}
						error={errors.loanPreference && errors.loanPreference.message}
					/>
				</div>
			)}
			{formValues.loanPreference?.id !== 0 && (
				<LoanTermFC
					register={register}
					showQuestion={formValues}
					onChangeLoanRefinancing={onChangeLoanRefinancing}
					selectedReasonForRefinancing={
						watch("requestedLoanRefinancingFeatures") || []
					}
					onChangeLoanBuyingFeatures={onChangeLoanBuyingFeatures}
					selectedLoanBuyingFeatures={
						watch("requestedLoanBuyingFeatures") || []
					}
					errors={errors}
				/>
			)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					dispatch(
						navigateToStep({
							step: "loanStep",
							subStep: SubStep.CurrentHomeLoan,
						})
					);
				}}
			/>
			{showSubmitButton && (
				<Button
					text="Next"
					variant="primary"
					iconSuffix={<i className="icon-arrow" />}
				/>
			)}
		</div>
	);
	return (
		<>
			<FormLayout
				header={header}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
			<Modal
				isOpen={showModal}
				actions={[]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						You've indicated the features our loans have don't meet your needs.
					</div>
				}
				onClose={() => {
					setShowModal(false);
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							We're working hard to bring you more features that meet your
							needs. Feel free to submit your application and we'll be in touch
							when this changes.
						</p>
						<p className="text-sm text-primary text-center">
							Otherwise, please select another option.
						</p>
					</div>
				}
			/>
		</>
	);
};

export default NewHomeLoan;
