import { type FC, useCallback, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import Button from "../../../../ui/button/Button";
import GenericInput from "../../../generic-input/GenericInput";
import { FileUpload, type UploadCallbackParameters } from "./FileUpload";
import { useAddCaseResponse } from "../../../../../hooks/origination/use-add-case-response";
import { captureException } from "../../../../../services/sentry";
import { useTracking } from "../../../../../hooks/use-tracking";

const sendMessageSchema = z
	.object({
		message: z.string().optional(),
		fileId: z.number().optional(),
		name: z.string().optional(),
	});

export type SendMessageSchemaFormType = z.infer<typeof sendMessageSchema>;

type ChatSendProps = {
	caseId: number;
	applicationId: number;
	uploadEnabled?: boolean;
	onAddResponse: (data: SendMessageSchemaFormType) => void;
	onRevertResponse: () => void;
	onAddFiles: (files: {fileId: number; name: string}[]) => void;
}
export const ChatSend: FC<ChatSendProps> = ({
	caseId,
	applicationId,
	uploadEnabled,
	onAddResponse,
	onRevertResponse,
	onAddFiles,
}) => {
	const [warning, setWarning] = useState<string>();

	const { isUploading, setIsUploading, onUpload, onMessage } = useAddCaseResponse();
	const { trackEvent } = useTracking();

	const formOptions = {
		resolver: zodResolver(sendMessageSchema),
		defaultValues: {},
	};
	const {
		register,
		setValue,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<SendMessageSchemaFormType>(formOptions);

	const message = watch('message');

	const sendMessage: SubmitHandler<SendMessageSchemaFormType> = useCallback(async (data) => {
		if (!data.message) {
			return;
		}
		onAddResponse({message: data.message})
		const oldMessage = data.message;
		setValue('message', '')
		try {
			await onMessage(caseId, data.message);
		} catch (error) {
			captureException(error as Error);
			onRevertResponse();
			setValue('message', oldMessage);
			toast.error("Message failed to send, please try again later.");
		}
	}, [caseId, onAddResponse, onMessage, onRevertResponse, setValue]);

	const onUploadCallback = useCallback((uploadedFiles?: UploadCallbackParameters[]) => {
		const okFiles = uploadedFiles?.filter(itm => itm?.error === undefined);
		if (okFiles?.length) {
			onAddFiles(okFiles);
		}
		onUpload(caseId, okFiles);
		const failedFiles = uploadedFiles
			?.filter(itm => itm?.error !== undefined)
			?.map(itm => `${itm.name} ${itm.error}`) ?? [];
		if (failedFiles.length > 0) {
			setWarning(failedFiles.join(', '))
		}
	}, [caseId, onUpload, onAddFiles])

	return (<>
		{!isUploading && <form onSubmit={handleSubmit(sendMessage)}>
			{warning && <div className="flex flex-row flex-nowrap p-4 bg-red-brick-10 text-red-brick-100">
				<i className="icon-warning" /> {warning}
			</div>}
			<div className="flex flex-row items-center gap-2 p-4 bg-white">
				<div className="w-full">
					<GenericInput
						name="message"
						register={register}
						type="text"
						placeholder="Type your message here"
						error={errors.message && errors.message?.message}
						onKeyDown={() => true}
					/>
				</div>
				{message && <Button
					className="min-w-fit w-10 h-10 px-0 py-0 rounded"
					type="submit"
					text={<i className="icon-send text-xl" />}
					variant="primary"
					textAlign="center"
				/>}
				{uploadEnabled && <Button
					className="min-w-fit w-10 h-10 px-0 py-0 rounded"
					text={<i className="icon-upload text-xl" />}
					type="button"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						trackEvent("upload_clicked", {
							fromWhere: "chat_send",
						});
						setIsUploading(true)
					 }}
				/>}
			</div>
		</form>}
		{isUploading && <FileUpload applicationId={applicationId} onUpload={onUploadCallback} />}
	</>);
};
