import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	setApplicationType,
} from "../../../../../store/slices/form-slice";
import {
	setDynamicHints,
} from "../../../../../store/slices/ui-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import Button from "../../../../ui/button/Button";
import { applicationTypeOptions } from "../../../../utils/BootList";
import RadioGroup from "../../../radio-group/RadioGroup";
import {
	LoanPurpose,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";


const schema = z.object({
	applicationType: z.string()
});

type ApplicationTypeFormType = z.infer<typeof schema>;

type InitialFormType = {
	applicationType?: string;
};

const ApplicationType = () => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);
	const { applicationType } = formState;
	const ApplicationTypeDefaultValues: InitialFormType = {
		applicationType,
	};

	const {
		register,
		handleSubmit,
		formState: { isValid, isDirty },
	} = useForm<ApplicationTypeFormType>({
		resolver: zodResolver(schema),
		defaultValues: { ...ApplicationTypeDefaultValues },
	});
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "Who is on the application?",
	});

	const saveData: SubmitHandler<ApplicationTypeFormType> = (
		data: ApplicationTypeFormType
	) => {
		dispatch(setApplicationType(data.applicationType));
		dispatch(setDynamicHints([]));
		dispatch(
			navigateToStep({
				step: "detailsStep",
				subStep: SubStep.TellUsAboutYou,
			})
		);
	};

	const header = null
	
	const content = (
			<RadioGroup
				radioOptions={applicationTypeOptions}
				legend="Who is on the application?"
				register={register}
			/>
	);
	const footer = (
		<div className="flex items-center justify-between gap-4">
			<Button
				text="Back"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					dispatch(setDynamicHints([]));
					if (formState.loanPurpose === LoanPurpose.Purchase) {
						dispatch(
							navigateToStep({
								step: "loanStep",
								subStep: SubStep.BuyingWorkflow,
							})
						);
					} else if (formState.loanPurpose === LoanPurpose.Refinance) {
						dispatch(
							navigateToStep({
								step: "loanStep",
								subStep: SubStep.NewHomeLoan,
							})
						);
					}
				}}
			/>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={!isValid}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default ApplicationType;
