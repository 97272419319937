import { useAppSelector } from "../../../hooks/redux-hooks";
import { useApplication } from "../../../hooks/origination/use-application";
import { useJointBorrower } from "../../../hooks/origination/use-joint-borrower";
import { selectUseOrigination } from "../../../store/slices/origination-slice";
import HintsColumn from "../hints-column/HintsColumn";
import { JointBorrowerStatus } from "./JointBorrowerStatus";

export interface ApplicationColumnProps {
    applicationId: number;
}

export const OriginationApplicationColumn = (props: ApplicationColumnProps) => {
    const application = useApplication(props.applicationId);
    const secondaryBorrower = useJointBorrower(application);
	return (
		<div className="sticky top-36 hidden md:flex flex-col gap-4">
            {secondaryBorrower && <JointBorrowerStatus applicant={secondaryBorrower} applicationId={props.applicationId} />}
            <HintsColumn sticky={false} />
		</div>
	);
};

export const ApplicationColumn = (props: ApplicationColumnProps) => {
    const useOrigination = useAppSelector(selectUseOrigination);
    return useOrigination ? <OriginationApplicationColumn {...props} /> : <HintsColumn />;
};