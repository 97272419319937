import Layout from "../common/layout/Layout";
import type { FunctionComponent } from "../common/types";
import { Box } from "../components/forms/steps/upload/components/Box";
import { Stepper } from "../components/layout/stepper/Stepper";
import { useAppSelector } from "../hooks/redux-hooks";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { selectHeaderHeight } from "../store/slices/ui-slice";

import { useEffect, useRef } from "react";

export const NotAGoodFit = (): FunctionComponent => {
	const topRef = useRef<HTMLDivElement>(null); // Ref for the top element or header
	const headerHeight = useAppSelector(selectHeaderHeight) ?? 100;

	useTrackPageViewOnMount({
		page: "Not a Good Fit",
	});
	useEffect(() => {
		const timer = setTimeout(() => {
			if (topRef.current) {
				const elementPosition = topRef.current.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.pageYOffset - headerHeight * 2;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}
		}, 300); // Added a 300ms delay before scrolling

		return () => {
			clearTimeout(timer);
		}; // Cleanup the timeout on component unmount
	}, [headerHeight]);

	return (
		<Layout
			leftColumn={<Stepper />}
		>
			
				
				<h3
					className="text-primary text-2xl md:text-[37.9px] font-normal md:whitespace-nowrap overflow-x-auto md:overflow-x-visible mb-6"
					aria-labelledby="Not a good fit."
				>
					Looks like Sucasa isn't a good fit right now
				</h3>
				<Box className="flex flex-col gap-4">
					<p>
						If your circumstances change, let us know, we'd love to hear from you.
					</p>
				</Box>


		</Layout>
	);
};

