export const NamedRoute = {
	welcome: "/app/welcome-back" as const,
	verification: "/app/verification" as const,
	intro: "/" as const,
	notInRemit: "not-in-remit" as const,
	home: "/home" as const,
	login: "/login" as const,
	forgotPassword: "/forgot-password" as const,
	resetPassword: "/reset-password" as const,
	acceptInvite: "/accept-invite" as const,
	acceptApplicantInvite: "/applicant-invite/:invite" as const,
	jointBorrowerHome: "/joint-borrower-home" as const,
	jointBorrowerIntro: "/joint-borrower-intro" as const,
	gettingStarted: "/getting-started" as const,
	authCallback: "/auth/callback" as const,
	authLanding: "/auth/landing" as const,
	notAGoodFit: "/not-a-good-fit" as const,
};
