/* eslint-disable camelcase */

import { useForm } from "react-hook-form";
import type { OptionsType } from "../../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import {
	setCurrentLender,
	setExistingBorrowers,
	setLoansOnProperty,
	setRemainingTermInMonths,
	setRemainingTermInYears,
} from "../../../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../../store/slices/ui-slice";
import {
	MonthsOptions,
	YearsOptions,
	existingBorrowersOptions,
} from "../../../../../utils/BootList";
import GenericInput from "../../../../generic-input/GenericInput";
import RadioGroup from "../../../../radio-group/RadioGroup";
import LoanOnPropertyForm from "./loan-on-property-form/LoanOnPropertyForm";
import { useEffect } from "react";
import {
	type LoanOnProperty, LoanOnPropertyType, LoanPurpose,
	SubStep,
} from "../../../../../../services/apis/create-application.schema";

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../../store";
import { zodResolver } from "@hookform/resolvers/zod";
import SelectInput from "../../../../select-input/SelectInput";
import Button from "../../../../../ui/button/Button";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import useIsMobile from "../../../../../../hooks/use-is-mobile";
import { LoanHint } from "../../../../../ui/hint/LoanHint";
import FormLayout from "../../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../../hooks/use-mark-form-dirty";
import useTrackPageViewOnMount from "../../../../../../hooks/use-track-on-mount";

const CurrentHomeLoanSchema = z
	.object({
		currentLender: z
			.string({
				invalid_type_error: "Please enter your current lender's name",
				required_error: "Please enter your current lender's name",
			})
			.min(1, "Please enter your current lender's name"),
		existingBorrowers: z.enum(["1", "2"], {
			invalid_type_error: "This field is required",
			required_error: "This field is required",
		}),
		remainingTermInYears: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		remainingTermInMonths: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		loansOnProperty: z
			.object({
				amount: z
					.number({
						invalid_type_error: "This field is required",
					})
					.min(1, "This field is required"),
				interestRate: z
					.number({
						invalid_type_error: "This field is required",
					})
					.min(1, "This field is required"),
				monthlyRepayment: z
					.number({
						invalid_type_error: "This field is required",
					})
					.min(1, "This field is required"),
				type: z.literal(LoanOnPropertyType.Fixed).or(z.literal(LoanOnPropertyType.Variable)),
				fixedRateExpiryDate: z.string().optional(),
			})
			.partial()
			.array(),
	})
	.superRefine(({ loansOnProperty }, context) => {
		// superRefine is only called when all the values are present.
		// In order to make conditional rules work, we need to make the object
		// partial and then re-validate the fields in superRefine.
		loansOnProperty?.forEach(({
		  type,
		  amount,
		  interestRate,
		  monthlyRepayment,
		  fixedRateExpiryDate
		}, index) => {
			if (!amount) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `This field is required`,
					path: ["loansOnProperty", index, "amount"],
				});
			}
			if (!interestRate) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `This field is required`,
					path: ["loansOnProperty", index, "interestRate"],
				});
			}
			if (!monthlyRepayment) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `This field is required`,
					path: ["loansOnProperty", index, "monthlyRepayment"],
				});
			}
			if (type === LoanOnPropertyType.Fixed && !fixedRateExpiryDate) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `This field is required if interest type is fixed`,
					path: ["loansOnProperty", index, "fixedRateExpiryDate"],
				});
			}
		});
	});

export type CurrentHomeLoanSchemaFormType = z.infer<
	typeof CurrentHomeLoanSchema
>;
export type CurrentHomeLoanSchemaSlice = {
	currentLender: string;
	existingBorrowers: "1" | "2";
	remainingTermInYears: OptionsType;
	remainingTermInMonths: OptionsType;
	loansOnProperty: Array<LoanOnProperty>;
};

const selectCurrentHomeLoan = createSelector(
	(state: RootState) => state.form,
	(form) => ({
		currentLender: form.currentLender,
		existingBorrowers: form.existingBorrowers!,
		remainingTermInYears: form.remainingTermInYears,
		remainingTermInMonths: form.remainingTermInMonths,
		loansOnProperty: form.loansOnProperty!,
		loanPurpose: form.loanPurpose,
	})
);

const remainingTermInYears = {
	id: 0,
	label: "Select years",
	value: "select_option",
};
const remainingTermInMonths = {
	id: 0,
	label: "Select months",
	value: "select_option",
};

const CurrentHomeLoan = () => {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(selectCurrentHomeLoan);
	const {
		register,
		handleSubmit,
		watch,
		control,
		setValue,
		formState: { errors, isDirty },
	} = useForm<CurrentHomeLoanSchemaFormType, CurrentHomeLoanSchemaSlice>({
		resolver: zodResolver(CurrentHomeLoanSchema),
		defaultValues: formState,
	});

	useMarkFormDirty(isDirty);

	useTrackPageViewOnMount({
		page: "Your Loan",
		subPage: "Refinance - Current Home Loan",
	});

	const saveData = (data: CurrentHomeLoanSchemaSlice) => {
		dispatch(setCurrentLender(data.currentLender));
		dispatch(setExistingBorrowers(data.existingBorrowers));
		dispatch(setRemainingTermInYears(data.remainingTermInYears));
		dispatch(setRemainingTermInMonths(data.remainingTermInMonths));
		dispatch(setLoansOnProperty(data.loansOnProperty));
		dispatch(
			navigateToStep({ step: "loanStep", subStep: SubStep.NewHomeLoan })
		);
	};

	useEffect(() => {
		dispatch(setDynamicHints([]));
		if (formState?.loanPurpose === LoanPurpose.Refinance) {
			dispatch(addDynamicHint("LoanHint"));
		}
		return () => {
			dispatch(removeDynamicHint("LoanHint"));
		};
	}, [dispatch, formState?.loanPurpose]);

	const header = (
		<h1 className="text-primary text-[37.9px] font-normal mb-4 lg:mb-8">
			Your Current Home Loan
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<GenericInput
				name="currentLender"
				register={register}
				type="text"
				label="Who is your current lender?"
				placeholder="Lender name"
				error={errors.currentLender && errors.currentLender.message}
			/>
			<div className="w-full flex flex-col gap-2">
				<legend className="text-primary text-[21.33px] font-normal mb-2">
					What is the remaining term on your mortgage?
				</legend>
				<div className="w-full flex items-center gap-4">
					<SelectInput
						name="remainingTermInYears"
						value={watch("remainingTermInYears") || remainingTermInYears}
						options={YearsOptions}
						register={register}
						error={
							errors.remainingTermInYears && errors.remainingTermInYears.message
						}
					/>
					<SelectInput
						name="remainingTermInMonths"
						value={watch("remainingTermInMonths") || remainingTermInMonths}
						options={MonthsOptions}
						register={register}
						error={
							errors.remainingTermInMonths &&
							errors.remainingTermInMonths.message
						}
					/>
				</div>
			</div>
			{((watch("remainingTermInYears")?.id ?? 0) > 0 ||
				(watch("remainingTermInMonths")?.id ?? 0) > 0) && (
				<RadioGroup
					radioOptions={existingBorrowersOptions}
					legend="How many borrowers are on your mortgage?"
					register={register}
					error={errors.existingBorrowers && errors.existingBorrowers.message}
				/>
			)}
			{watch("existingBorrowers") && (
				<LoanOnPropertyForm
					control={control}
					initialLoanOnProperties={watch("loansOnProperty")}
					onChange={(loansOnProperty) => {
						setValue("loansOnProperty", loansOnProperty);
					}}
					errors={errors.loansOnProperty && errors.loansOnProperty}
				/>
			)}
			{isMobile && (<LoanHint />)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-between gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Back"
				type="button"
				variant="accent"
				iconPrefix={<i className="icon-arrow rotate-180" />}
				handleClick={() => {
					dispatch(
						navigateToStep({ step: "homeStep", subStep: SubStep.LetsStart })
					);
				}}
			/>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default CurrentHomeLoan;
