import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "react-toastify";
import { useOtpVerifyMutation } from "../../../services/api";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import Button from "../../ui/button/Button";
import { ResendTokenButton } from "../../ui/button/ResendTokenButton";
import { setFormLoading } from "../../../store/slices/loader-slice";
import { selectVerificationToken } from "../../../store/slices/auth-slice";
import { setLogoutModal } from "../../../store/slices/ui-slice";
import { selectApplicationPayload } from "../../../store/selectors/application-payload";
import { NamedRoute } from "../../utils/NamedRoutes";
import { useAuthLock } from "../../../hooks/use-auth-context";
import { useAuthSuccessHandler } from "../../../hooks/use-auth-success";
import { PinInput } from "../pin-input/PinInput";
import { DEFAULT_TOKEN_TYPE, OauthTokenType } from "../../../services/apis/auth.schema";

const submitYourPinSchema = z
	.object({
		token: z.coerce.string().length(6, "A valid verification code is required"),
		verificationToken: z.string(),
	});

type SubmitYourPinSchemaType = z.infer<typeof submitYourPinSchema>;

export const SubmitYourPin = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { setAuthLocked } = useAuthLock();
	const { handleSuccess } = useAuthSuccessHandler();
	const verificationToken = useAppSelector(selectVerificationToken);
	const applicationPayload = useAppSelector(selectApplicationPayload);
	const [otpVerify, { isLoading }] = useOtpVerifyMutation();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
	} = useForm<SubmitYourPinSchemaType>({
		resolver: zodResolver(submitYourPinSchema),
		defaultValues: {
			token: "",
			verificationToken,
		},
	});

	const saveData = useCallback<SubmitHandler<SubmitYourPinSchemaType>>(async (data) => {
		try {
			setAuthLocked(true);
			dispatch(setFormLoading(true));

			const response = await otpVerify(data).unwrap();
			// there is a weird bug if a user is polling application in a separate tab
			// we need to reset logout modal
			dispatch(setLogoutModal(false));
			handleSuccess({
				authToken: response.authToken,
				...response.data.content,
			});
			navigate(NamedRoute.jointBorrowerIntro);
		} catch (error) {
			// @ts-expect-error need to type api responses
			 
			toast.error(error?.data?.message || error?.data?.data?.message || "An error occurred while accepting your invite.");
			setValue("token", "");
		} finally {
			dispatch(setFormLoading(false));
			setAuthLocked(false);
		}
	}, [dispatch, handleSuccess, navigate, otpVerify, setAuthLocked, setValue])

	const identifiers = useMemo(
		() => ({
			identifierType: DEFAULT_TOKEN_TYPE === OauthTokenType.Email ? "email" : "phone",
			value: DEFAULT_TOKEN_TYPE === OauthTokenType.Email ? applicationPayload?.email : applicationPayload?.mobile,
		}),
		[applicationPayload?.email, applicationPayload?.mobile]
	);

	return (
		<form
			className="flex flex-col gap-8"
			onSubmit={handleSubmit(saveData)}
		>
			<PinInput
				name="token"
				control={control}
				length={6}
				label={`Enter the code sent to your ${identifiers.identifierType} below:`}
				helperMessage={`Enter the code we sent to ${identifiers.value}`}
				size="medium"
				error={errors.token && errors.token?.message}
			/>
			<div className="flex flex-col gap-2">
				<div>Didn’t receive a code? Check your spam or junk folder, or click “Resend Code” below.</div>
				<div className="flex items-center justify-between gap-2 w-1/2">
				<span onClick={(event) => {
					event.stopPropagation();
					event.preventDefault();
				}}>
					<ResendTokenButton />
				</span>
				</div>
			</div>

			<div className="lg:mt-10">
				<Button
					type="submit"
					text="Verify and Log in"
					variant="primary"
					size="full"
					iconSuffix={<i className="icon-arrow" />}
					isDisabled={isLoading || !isValid}
				/>
			</div>
		</form>
	);
};
