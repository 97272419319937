import { z } from "zod";
import type { User } from "./auth";

export enum MaritalStatus {
	Single = "Single",
	Married = "Married",
	DeFacto = "De-Facto",
	Divorced = "Divorced",
	Seperated = "Separated",
}

export enum LoanFeature {
	InterestOnly = "Interest Only",
	FixedRate = "Fixed Rate",
	OffsetAccount = "Offset Account",
	GuarantorLoan = "Guarantor Loan",
	Other = "Other", // Assuming 'Other' can be a placeholder for free text
}

export enum ReasonForRefinancing {
	ReduceMonthlyRepayments = "Reduce Monthly Repayments",
	ImproveInterestRate = "Improve Interest Rate",
	NotHappyWithExistingLender = "Not happy with existing lender",
	MoreFlexibility = "More flexibility (Redraw)",
	AdditionalCashOut = "Additional Cash Out",
	DebtConsolidation = "Debt Consolidation",
	Other = "Other", // Assuming 'Other' can be a placeholder for free text
}

export enum ResidencyStatus {
	AU = "AU",
	NZ = "NZ",
	PermanentResident = "Permanent Resident",
	OtherVisa = "Other Visa",
}

export enum BorrowerStage {
	Threemonths = "3 Months +",
	Under3months = "Under 3 Months",
	AnyDayNow = "Any day now, I have a property in mind",
	Signed = "I have a signed purchase contract",
}

export enum LoanOnPropertyType {
	Fixed = "Fixed",
	Variable = "Variable"
}

export interface PrimaryApplicantDetails {
	title: string;
	firstName: string;
	middleName?: string;
	surname: string;
	maritalStatus: MaritalStatus;
	marriedToApplicantOnApplication: boolean;
}

export interface LoanOnProperty {
	amount?: number | undefined;
	interestRate?: number | undefined;
	monthlyRepayment?: number | undefined;
	type?: LoanOnPropertyType;
	fixedRateExpiryDate?: string; // dd/mm/yyyy
	remainingTermInYears?: {
		id: number;
		label: string;
		value: string;
	};
	remainingTermInMonths?: {
		id: number;
		label: string;
		value: string;
	};
}

export enum LoanPurpose {
	Purchase = "Purchase",
	Refinance = "Refinance",
	Empty = "",
}

export enum CreditCheckPreference {
	CallMeFirst = "callMeFirst",
	AtSubmission = "atSubmission",
}

export enum PropertyType {
	VacantLand = "Vacant Land",
	HouseAndLand = "House and Land",
}

export enum PropertyPurpose {
	Investment = "Investment",
	LiveIn = "Live In",
}

export enum ApplicationType {
	Joint = "Joint",
	Single = "Single",
}

export enum LoanPreference {
	LowerUpfrontFees = "Lower Upfront Fees",
	LowerInterestRate = "Lower Interest Rate",
	NoPreference = "No Preference",
}

export enum ExitStrategy {
	ByUsingMySavings = "By using my savings",
	ByReducingMyExpenses = "By reducing my expenses",
	ByDownsizingMyHome = "By downsizing my home",
	BySecuringAdditionalIncome = "By securing additional income",
	BySaleOfMyAssets = "By sale of my assets",
	ByUtilisingMySuperannuation = "By utilising my superannuation",
	ByContinuingToWork = "I’ll continue working",
	Other = "Other", // Assuming 'Other' can be a placeholder for free text
}
export enum Step {
	Submitted = "Submitted",
	Confirmation = "Confirmation",
	YourFinances = "Your Finances",
	YourDetails = "Your Details",
}
export enum SubStep {
	Empty = "",
	LetsStart = "letsStart",
	Welcome = "Welcome",
	BeforeWeGetStarted = "beforeWeGetStarted",
	BuyingWorkflow = "buyingWorkflow",
	CurrentHomeLoan = "currentHomeLoan",
	NewHomeLoan = "newHomeLoan",
	ApplicationType = "applicationType",
	TellUsAboutYou = "tellUsAboutYou",
	YouAndYourHousehold = "youAndYourHousehold",
	FHBFederalGuarantee = "fhbFederalGuarantee",
	CreateYourAccount = "createYourAccount",
	NotInRemit = "not-in-remit",
	FineDetailsAboutYou = "Final Details About You",
	JointBorrowerAboutYou = "joint-borrower-about-you",
	TwoYearsAddressHistory = "TwoYearsAddressHistory",
	JointApplicantInvite = "jointApplicantInvite",
	YourEmployment = "Your Employment",
	YourEmployeeIncome = "your-employee-income",
	YourBusinessIncome = "your-business-income",
	YourJointBorrowerIncome = "your-joint-borrower-income",
	YourHouseholdExpenses = "Your Expenses",
	YourAssets = "Your Assets",
	YourLiabilities = "Your Liabilities",
	YourCircumstances = "Your Circumstances",
	YourHelp = "your-help",
	Completed = "completed",
	Submitted = "submitted",
}
const addressPartsSchema = z.object({
	streetNumber: z.string().optional(),
	streetName: z.string().optional(),
	streetType: z.string().optional(),
	suburb: z.string(),
	state: z.string(),
	postalCode: z.string(),
	unitNumber: z.string().optional(),
	stateOptions: z
		.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		})
		.optional(),
});
const addressPartsAPISchema = z.object({
	streetNumber: z.string().optional(),
	streetName: z.string().optional(),
	suburb: z.string(),
	state: z.string(),
	postalCode: z.string(),
	unitNumber: z.string().optional(),
	country: z.string().optional(),
});
export type AddressParts = z.infer<typeof addressPartsSchema>;
export type AddressPartsAPI = z.infer<typeof addressPartsAPISchema>;

const loanOnPropertySchema = z.object({
	amount: z.number().optional(),
	interestRate: z.number().optional(),
	monthlyRepayment: z.number().optional(),
	type: z.union([z.literal("Fixed"), z.literal("Variable"), z.literal("")]),
	fixedRateExpiryDate: z.string().optional(),
});

const primaryApplicationDetailsSchema = z.object({
	title: z.string(),
	firstName: z.string(),
	middleName: z.string().optional(),
	surname: z.string(),
	maritalStatus: z.nativeEnum(MaritalStatus),
	marriedToApplicantOnApplication: z.boolean(),
});

const applicationPayloadSchemaBase = z
	.object({
		applicationStep: z.string().optional(),
		applicationSubStep: z.string().optional(),
		creditCheckPreference: z.nativeEnum(CreditCheckPreference),
		purpose: z.nativeEnum(LoanPurpose),
		stage: z.nativeEnum(BorrowerStage),
		propertyType: z.nativeEnum(PropertyType),
		addressParts: addressPartsAPISchema,
		reason: z.nativeEnum(PropertyPurpose),
		firstHomeBuyer: z.boolean(),
		purchasePrice: z.number().optional(),
		borrowAmount: z.number(),
		savings: z.number(),
		giftedAmount: z.number(),
		giftedAmountGiftedBy: z.string().optional(),
		loanPreference: z.nativeEnum(LoanPreference),
		loanTermInYears: z.union([z.literal(30), z.literal(25), z.literal(20)]),
		meetsNeedsAndObjectives: z.boolean(),
		requestedLoanFeatures: z.array(z.nativeEnum(LoanFeature)),
		applicationType: z.nativeEnum(ApplicationType),
		primaryApplicationDetails: primaryApplicationDetailsSchema,
		numberOfDependants: z.number(),
		dependantAges: z.array(z.number()),
		residencyStatus: z.nativeEnum(ResidencyStatus),
		visaSubclassNumber: z.string().optional(),
		email: z.string().email(),
		mobile: z.string(),
		marketingConsent: z.boolean(),
		privacyConsent: z.boolean(),
		propertyValuation: z.number().optional(),
		refinanceLoanCurrentLender: z.string().optional(),
		refinanceLoanCurrentRemainingTermInMonths: z.number().optional(),
		refinanceLoanCurrentBorrowers: z.number().optional(),
		loansOnProperty: z.array(loanOnPropertySchema),
		refinanceLoanAdditionalBorrowingAmount: z.number().optional(),
		refinanceLoanReasonForAdditionalFunds: z.string().optional(),
		refinanceLoanNewLoanHasSameBorrowersAsExistingLoan: z.boolean().optional(),
		reasonsForRefinancing: z
			.array(z.nativeEnum(ReasonForRefinancing))
			.optional(),
		otherReasonForRefinancing: z.string().optional(),
		otherRequestLoanFeature: z.string().optional(),
		utmCampaign: z.string().optional(),
		utmContent: z.string().optional(),
		utmMedium: z.string().optional(),
		utmSource: z.string().optional(),
		utmTerm: z.string().optional(),
		utmId: z.string().optional(),
		fbclid: z.string().optional(),
		gclid: z.string().optional(),
	});

export const applicationPayloadSchema = applicationPayloadSchemaBase
	.extend({
		primaryBorrowerPassword: z.string(),
		primaryBorrowerPasswordConfirmation: z.string(),
	})
	.superRefine((data, context) => {
		if (
			data.stage === BorrowerStage.Signed &&
			(!data.addressParts.streetNumber || !data.addressParts.streetName)
		) {
			context.addIssue({
				path: ["addressParts.streetNumber"],
				message:
					"Street number and street name are required if stage is 'I've signed a purchase contract'",
				code: "custom",
			});
		}
	});
export type CreateApplicationRequest = z.infer<typeof applicationPayloadSchema>;

export const applicationPayloadSchemaV2 = applicationPayloadSchemaBase
	.extend({
		verificationToken: z.string().optional(),
		token: z.string().optional(),
	})
	.superRefine((data, context) => {
		if (
			data.stage === BorrowerStage.Signed &&
			(!data.addressParts.streetNumber || !data.addressParts.streetName)
		) {
			context.addIssue({
				path: ["addressParts.streetNumber"],
				message:
					"Street number and street name are required if stage is 'I've signed a purchase contract'",
				code: "custom",
			});
		}
	});
export type CreateApplicationV2Request = z.infer<typeof applicationPayloadSchemaV2>;

export interface HomeLoanSpecialist {
	email: string;
	name: string;
	phone: string;
	meetingLink: string;
}
export interface Application {
	createdDate: string;
	appId: string;
	applicationStep: string;
	applicationSubStep: string;
	applicationType: string;
	jointApplicantHasBeenInvited: boolean;
	purposeOfApplication: string;
	applicationVersionCanUseNewPortal: boolean;
	loanDetails: {
		propertyAddress: string;
		purchasePrice: string;
		loanAmount: string;
		loanTermInYears: number;
	};
	applicationId?: string;
	status?: string;
	workingTowardsStatus?: string;
}

export interface CreateApplicationResponse {
	data: {
		content: {
			notInRemit: boolean;
			applicationDetails: Application;
			homeLoanSpecialist: HomeLoanSpecialist;
			userDetails: User;
		};
	}
}
