import { useCallback, useState } from "react";
import { useTracking } from "../use-tracking";
import { origination } from "../../services/origination";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { emailSchema, mobileSchema } from "../../services/apis/joint-applicant-invite";
import { useForm } from "react-hook-form";
import { useMarkFormDirty } from "../use-mark-form-dirty";
import type { ApplicantOutput } from "@sucasa-finance/origination-trpc";

const jointBorrowerInviteSchema = z.object({
	jointBorrowerEmail: emailSchema.optional(),
	jointBorrowerPhone: mobileSchema.optional(),
});
type JointBorrowerInviteFormType = z.infer<typeof jointBorrowerInviteSchema>;

export interface UseJointBorrowerResendOptions {
	applicant: ApplicantOutput
	applicationId: number
}

export const useJointBorrowerResend = ({ applicant, applicationId }: UseJointBorrowerResendOptions) => {
    const [isEditing, setIsEditing] = useState(false);
	const onToggleEdit = useCallback(() => { setIsEditing(previous => !previous); }, []);
	const { trackEvent } = useTracking();
	const utils = origination.useUtils();
	const inviteJointApplicant = origination.application.inviteJointApplicant.useMutation({
		onSuccess() {
			return utils.application.getApplicationById.invalidate({
				applicationId
			});
		}
	});
	const formOptions = {
		resolver: zodResolver(jointBorrowerInviteSchema),
		defaultValues: {
			jointBorrowerEmail: applicant.email,
			jointBorrowerPhone: applicant.phone,
		},
	};
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<JointBorrowerInviteFormType>(formOptions);
	useMarkFormDirty(isDirty);

	const onSubmit = useCallback((data: JointBorrowerInviteFormType) => {
		setIsEditing(false);
		trackEvent("resend_invite_coborrower_clicked", { applicationId });
		const { jointBorrowerEmail: email, jointBorrowerPhone: phone } = data;
		const fieldsChanged = [];
		if (email && email !== applicant.email) {
			fieldsChanged.push(["email", email]);
		}
		if (phone && phone !== applicant.phone) {
			fieldsChanged.push(["phone", email]);
		}
		if (fieldsChanged.length > 0) {
			trackEvent("coborrower_details_edited", { applicationId, fieldsEdited: Object.fromEntries(fieldsChanged) });
		}
		inviteJointApplicant.mutate({
			firstName: applicant.firstName,
			lastName: applicant.lastName,
			email: email || applicant.email,
			phone: phone || applicant.phone,
			applicationId: applicationId 
		})
	}, [inviteJointApplicant, applicationId, applicant, trackEvent])

	const validationErrorMessage = (
		errors.jointBorrowerEmail?.message ||
		errors.jointBorrowerPhone?.message
	);

    return {
        register,
        handleSubmit,
        errors,
        isDirty,
        onSubmit,
        validationErrorMessage,
        isEditing,
        onToggleEdit,
        inviteJointApplicant,
    } as const;
}