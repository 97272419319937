import { useMemo } from "react";
import { ApplicationStep, ApplicationSubStep, type ApplicantOutput } from "@sucasa-finance/origination-trpc";

export const useApplicantInviteAccepted = (applicant?: ApplicantOutput) => {
    return useMemo(() => {
		return Boolean(
			// If there is a step other than invited, something is happening.
			applicant?.applicationSteps.find(item => item.step === ApplicationStep.Invited && item.subStep !== ApplicationSubStep.Invited)
		);
	}, [applicant]);
}