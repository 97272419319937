import type { UpdateDetailResponse, UpdateDetailsRequest } from '../../update-details.schema';
import type { ExitStrategy, LivingSituation, ApplicationUserDetails, UpdateApplication, ApplicationUserDetailsWithIds } from '@sucasa-finance/origination-trpc';
import { livingSituationMapping, maritalStatusMapping } from './enum-mapping';
import { mapAddressHistoryItem } from './address';
import type { UpdateCircumstancesRequest, UpdateCircumstancesResponse } from '../../update-circumstances.schema';
import { emptyOkResponse } from './response';
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { selectCurrentOriginationUser } from "../../../../store/slices/auth-slice";
import { useCallback } from 'react';
import { mapStepAndSubStep } from './step';
import { formatDate } from './date';
import type { MaritalStatus } from '../../create-application.schema';

export function mapStringToTarget<T>(source: string | undefined, mapping: Record<string, T>): T | undefined {
    if (!source) return undefined;
    const found = mapping[source];
    if (!found) return undefined;
    return found;
}

export function mapExitStrategy(exitStrategy?: string): Array<ExitStrategy | string> {
    return exitStrategy?.split(',') ?? []
}

export function mapLivingSituation(livingSituation?: string): LivingSituation | undefined {
    return mapStringToTarget(livingSituation, livingSituationMapping);
}

// TODO(janyk): remove MaritalStatus union type once backend supports it    
export function mapUpdateApplicantDetailsRequest(from: UpdateDetailsRequest, applicationId: string | number, userId: string | number | undefined): ApplicationUserDetailsWithIds & { maritalStatus?: MaritalStatus | null } {
    const {
        birthdate,
        exitStrategy,
        exitStrategyDescription,
        livingSituation,
        addressHistory,
        privacyConsent,
        marketingConsent,
        creditCheckPreference,
        residencyStatus,
        visaSubclassNumber,
        maritalStatus,
    } = from;
    return {
        data: {
            dateOfBirth: formatDate(birthdate, "-"),
            exitStrategy: mapExitStrategy(exitStrategy),
            exitStrategyDescription,
            privacyConsent,
            marketingConsent: !marketingConsent,
            creditCheckPreference,
            livingSituation: mapLivingSituation(livingSituation),
            addresses: (addressHistory ?? []).map(item => mapAddressHistoryItem(item)),
            residencyStatus: residencyStatus,
            visaSubclassNumber: visaSubclassNumber,
            ...(maritalStatus && { maritalStatus: maritalStatusMapping[maritalStatus as MaritalStatus] }),
        },
        applicationId: Number(applicationId),
        userId: Number(userId),
        ...mapStepAndSubStep(from),
    }
}


export function useMapUpdateApplicantDetailsRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback((request: UpdateDetailsRequest) => mapUpdateApplicantDetailsRequest(request, applicationId, user?.userId), [applicationId, user?.userId]);
}

export function mapUpdateApplicantDetailsResponse(_unused: void, request: UpdateDetailsRequest): UpdateDetailResponse {
    return {
        data: {
            status: "",
            statusCode: 200,
            message: "",
            content: {
                success: true,
                message: "",
                content: {
                    ...request,
                    addressHistory: request.addressHistory || [],
                    subStepToSave: "",
                }
            }
        }
    }
}

export function mapUpdateApplicantCircumstancesRequest(from: UpdateCircumstancesRequest, applicationId: string | number, userId: string | number | undefined): { data: ApplicationUserDetails, applicationId: number, userId: number } {
    const {
        offShoreLiabilities,
        adverseCreditHistory,
        anticipateChanges,
    } = from;
    return {
        data: {
            disclosedOffshoreLiabilities: offShoreLiabilities.indicated,
            offshoreLiabilities: offShoreLiabilities.indicated ? offShoreLiabilities.description : undefined,
            disclosedAdverseCreditHistory: adverseCreditHistory.indicated,
            adverseCreditHistory: adverseCreditHistory.indicated ? adverseCreditHistory.description : undefined,
            disclosedExpectedImpact: anticipateChanges.indicated,
            expectedImpact: anticipateChanges.indicated ? anticipateChanges.description : undefined,
        },
        applicationId: Number(applicationId),
        userId: Number(userId),
        ...mapStepAndSubStep(from),
    }
}

export function useMapUpdateApplicantCircumstancesRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    return useCallback((request: UpdateCircumstancesRequest) => mapUpdateApplicantCircumstancesRequest(request, applicationId, user?.userId), [applicationId, user?.userId]);
}

export function mapUpdateApplicantCircumstancesResponse(): UpdateCircumstancesResponse {
    return emptyOkResponse();
}

export function mapUpdateApplicationCircumstancesRequest(from: UpdateCircumstancesRequest, applicationId: string | number): UpdateApplication {
    const {
        idealPurchasePriceRange,
        idealMonthlyRepaymentMaximum,
        depositSizeForContribution,
        savingsEachMonth,
    } = from;
    return {
        id: Number(applicationId),
        idealDepositAmount: depositSizeForContribution ?? null,
        idealMonthlyRepayment: idealMonthlyRepaymentMaximum ?? null,
        idealPurchasePriceFrom: idealPurchasePriceRange?.fromAmount ?? null,
        idealPurchasePriceTo: idealPurchasePriceRange?.toAmount ?? null,
        declaredMonthlySavingsAmount: savingsEachMonth ?? null,
        ...mapStepAndSubStep(from),
    }
}

export function useMapUpdateApplicationCircumstancesRequest() {
    const applicationId = useAppSelector(selectFormApplicationId);
    return useCallback((request: UpdateCircumstancesRequest) => mapUpdateApplicationCircumstancesRequest(request, applicationId), [applicationId]);
}
