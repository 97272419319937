import { ApplicationStep, ApplicationSubStep } from "@sucasa-finance/origination-trpc";
import { useApplicantInviteAccepted } from "../../../hooks/origination/use-applicant-accepted";
import { useApplicantHasStep } from "../../../hooks/origination/use-applicant-has-step";
import { useJointBorrowerResend, type UseJointBorrowerResendOptions } from "../../../hooks/origination/use-joint-borrower-resend";
import GenericInput from "../../forms/generic-input/GenericInput";
import Button from "../../ui/button/Button";
import HintCard from "../../ui/hint-card/HintCard";
import { Status } from "../../ui/status/Status";


export interface JointBorrowerStatusProps extends UseJointBorrowerResendOptions {
	
}

export const JointBorrowerStatus = (props: JointBorrowerStatusProps) => {
	const { applicant } = props;
	const isAccepted = useApplicantInviteAccepted(applicant);
	const isSubmitted = useApplicantHasStep(applicant, ApplicationStep.Submitted, ApplicationSubStep.Submitted);
    const { register, handleSubmit, isDirty, onSubmit, validationErrorMessage, isEditing, onToggleEdit, inviteJointApplicant } = useJointBorrowerResend(props);
	return (
		<HintCard className="flex flex-col p-4 w-full" noGap>
			<div className={`flex flex-col divide-y devide-solid divide-black-10 my-4 border-b ${isEditing ? "border-b-slate-500" : ""} w-full`}>
			<div className="flex flex-row justify-between items-center pb-4">
				<div>
					<i className="icon-two-users" />&nbsp;
					<span className="font-medium">{applicant.firstName} {applicant.lastName}</span>
				</div>
				<Status 
					short
					status={isAccepted ? isSubmitted ? "active" : "alert" : "required"} 
					text={isAccepted ? isSubmitted ? "Submitted" : "Awaiting response" : "Yet to accept"}
					icon={isAccepted ? undefined : <i className="icon-warning" />}
				/>
			</div>
			<div className="flex flex-col py-4 gap-4">
				<div className="grow flex flex-row justify-between">
					<div className={`grow flex flex-row items-center ${isEditing ? "font-medium" : ""}`}>
						<i className="icon-email" />&nbsp;
						<GenericInput
							register={register}
							type="email" 
							disabled={!isEditing} 
							className="flex-grow" 
							inputContainerClassName="border-none p-0"
							inputClassName="text-off-black-900 overflow-ellipsis"
							name="jointBorrowerEmail"
							placeholder="Joint Applicant Email Address"
							noBorder
						/>
					</div>
					{
						(isAccepted || isEditing) ? undefined : (
							<Button
								text="Edit"
								className="min-w-min rounded"
								iconPrefix={<i className="icon-edit" />}
								variant="link"
								textAlign="center"
								handleClick={onToggleEdit}
							/>
						)
					}
				</div>
				<div className={`flex flex-row items-center ${isEditing ? "font-medium" : "tracking-wide"}`}>
					<i className="icon-mobile" />&nbsp;
					<GenericInput
						register={register} 
						type="text" 
						disabled={!isEditing} 
						inputContainerClassName="border-none pb-0"
						inputClassName="text-off-black-900 overflow-ellipsis"
						name="jointBorrowerPhone"
						placeholder="Joint Applicant Phone"
						noBorder
					/>
				</div>
			</div>
		</div>
		<div className="text-off-black-600">
		{
			isSubmitted ? (
					// `You will see ${applicant.firstName}'s asset submissions. Submissions from ${applicant.firstName} cannot be edited.`
					``
			) : isAccepted ? (
				`${applicant.firstName} still needs to complete their application.`
			) : (
				`${applicant.firstName} still needs to accept their invitation.`
			)
		}
		</div>
		{
			inviteJointApplicant.error || (isEditing && validationErrorMessage) ? (
				<div className="p-4 bg-peach-60 text-red-500">
					<i className="icon-warning" />&nbsp;{(isEditing && validationErrorMessage) || String(inviteJointApplicant.error?.message)}
				</div>
			) : undefined
		}
		{
			isAccepted ? undefined : (
				<div className="flex flex-row justify-between items-center w-full pt-4">
					<Button
						text="Resend Invite"
						className="min-w-min text-lg rounded"
						iconSuffix={<i className="icon-send" />}
						variant="primary"
						textAlign="center"
						isDisabled={Boolean(validationErrorMessage) || inviteJointApplicant.isPending || (!isDirty && inviteJointApplicant.isSuccess)}
						handleClick={handleSubmit(onSubmit)}
					/>
					{
						inviteJointApplicant.isSuccess ? (
							<span className="text-lg">
								Invite sent!
							</span>
						) : (
							(isEditing || inviteJointApplicant.isPending) ? <span /> : (<span/>
								// <Button
								// 	text="Talk to a consultant"
								// 	className="min-w-min text-lg rounded"
								// 	iconSuffix={<i className="icon-message" />}
								// 	variant="accent"
								// 	textAlign="center"
								// 	handleClick={() => {
								// 		// TODO add link to open chat
								// 		console.log("Talk to a consultant");
								// 	}}
								// />
							)
						)
					}
				</div>
			)
		}
		</HintCard>
	)
};