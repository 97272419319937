import { useMemo } from "react";
import type { ApplicantOutput, ApplicationStep, ApplicationSubStep } from "@sucasa-finance/origination-trpc";

export const useApplicantHasStep = (applicant: ApplicantOutput | undefined, step: ApplicationStep, subStep?: ApplicationSubStep) => {
    return useMemo(() => {
		return Boolean(
			// If there is a step other than invited, something is happening.
			applicant?.applicationSteps.find(item => item.step === step && (!subStep || item.subStep === subStep))
		);
	}, [applicant, step, subStep]);
}