import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../button/Button";
import { useLDFlags } from "../../../hooks/use-ld-flags";

const LoginLink = () => {
	const { auth0Login } = useLDFlags();
	const { loginWithRedirect } = useAuth0();

	return (
		<>
			{!auth0Login && <Link
				to="/login"
				className="flex items-center tracking-tight transition-all hover:text-off-black-600 underline font-medium"
				aria-labelledby="Sign In Link"
			>
				<span className="flex">Sign In</span>
			</Link>}
			{auth0Login && <Button
				type="button"
				className="flex items-center tracking-tight transition-all hover:text-off-black-600 underline font-medium"
				text="Sign In"
				variant="link-bold"
				handleClick={() => loginWithRedirect()}
			/>}
		</>
	);
};

export default LoginLink;
