import type { FunctionComponent } from "../../../../common/types";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import Button from "../../../ui/button/Button";
import {
	setBirthDate,
	setExitStrategy,
	setExitStrategyOther,
	setResidencyStatus,
	setVisaSubclass,
	setExitStrategyDetails,
	setMaritalStatus,
} from "../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	JointBorrowerAboutYouFormSchema,
	selectJointBorrowerAboutYou,
	type JointBorrowerAboutYouFormType,
} from "./AboutYouFormSchema";

import GenericInput from "../../generic-input/GenericInput";
import { isLoanTermValid } from "../../../utils/helpers";
import ExitStrategyFC from "../../steps/your-details/fine-details-about-you/exit-strategy/ExitStrategy";
import {
	ResidencyStatus,
	type ExitStrategy,
	SubStep,
} from "../../../../services/apis/create-application.schema";
import SelectInput from "../../select-input/SelectInput";
import { ResidencyStatusOptions } from "../../../utils/BootList";
import { DatePickerInput } from "../../date-picker-input/DatePickerInput";
import { navigateToJointStep } from "../../../../store/slices/joint-stepper-slice";
import { getMaxBirthDate } from "../../../../lib/utils";
import FormLayout from "../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../hooks/use-track-on-mount";
import { maritalStatusOptions } from "../../steps/your-details/you-and-your-household/your-household.form-options";
import { selectUseOrigination } from "../../../../store/slices/origination-slice";

const DEFAULT_OPTION = {
	id: 0,
	value: "",
	label: "Select an option",
};

const JointBorrowerAboutYouForm = (): FunctionComponent => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectJointBorrowerAboutYou);
	const useOrigination = useAppSelector(selectUseOrigination)

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<typeof formState, unknown, JointBorrowerAboutYouFormType>({
		resolver: zodResolver(JointBorrowerAboutYouFormSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "About You",
	});

	const birthDateFormValue = watch("birthDate");
	const residencyStatusFormValue = watch("residencyStatus");
	const maritalStatusFormValue = watch("maritalStatus");

	const shouldShowVisaSubclass = [
		ResidencyStatus.OtherVisa,
		ResidencyStatus.PermanentResident,
	].includes(residencyStatusFormValue?.value as ResidencyStatus);

	const backToPreviousState = (): void => {
		dispatch(
			navigateToJointStep({
				step: "detailsStep",
				subStep: SubStep.BeforeWeGetStarted,
			})
		);
	};

	const onChangeExitStrategy = (checked: boolean, value: string) => {
		const label = value as ExitStrategy;
		let updatedExitStrategy: Array<ExitStrategy> = [];
		const exitStrategy = watch("exitStrategy");
		if (exitStrategy) {
			updatedExitStrategy = [...exitStrategy];
		}
		const indexToCheck = updatedExitStrategy.indexOf(label);
		if (checked && indexToCheck === -1) {
			updatedExitStrategy.push(label);
		} else {
			updatedExitStrategy.splice(indexToCheck, 1);
		}
		setValue("exitStrategy", updatedExitStrategy);
	};

	const saveData = (data: JointBorrowerAboutYouFormType) => {
		dispatch(setBirthDate(data.birthDate));
		dispatch(setResidencyStatus(data.residencyStatus));
		dispatch(setVisaSubclass(data.visaSubclass!));
		dispatch(setExitStrategy(data.exitStrategy as Array<ExitStrategy>));
		dispatch(setExitStrategyOther(data.exitStrategyOther!));
		dispatch(setExitStrategyDetails(data.exitStrategyDetails!));
		if (useOrigination && data.maritalStatus) {
			dispatch(setMaritalStatus(data.maritalStatus));
		}
		dispatch(
			navigateToJointStep({
				step: "detailsStep",
				subStep: SubStep.TwoYearsAddressHistory,
			})
		);
	};

	return (
		<FormLayout
			header={
				<h1
					className="text-primary text-[37.9px] font-normal"
					aria-labelledby="Joint Borrower About You"
				>
					About You
				</h1>
			}
			content={
				<div className="flex flex-col gap-9">
					<DatePickerInput
						name="birthDate"
						onChange={(value) => {
							setValue("birthDate", value!);
						}}
						value={birthDateFormValue}
						label="What's your date of birth?"
						error={errors.birthDate?.message}
						maxDate={getMaxBirthDate()}
					/>
					{birthDateFormValue &&
						formState.loanTermInYears &&
						!isLoanTermValid(birthDateFormValue, formState.loanTermInYears) && (
							<>
								<ExitStrategyFC
									register={register}
									selectedExitStrategies={watch("exitStrategy") || []}
									onChange={onChangeExitStrategy}
									errors={errors}
								/>
							</>
						)}

					<SelectInput
						name="residencyStatus"
						value={residencyStatusFormValue || DEFAULT_OPTION}
						label="What is your Australian residency status?"
						options={ResidencyStatusOptions}
						register={register}
						error={errors.residencyStatus && errors.residencyStatus.message}
					/>
					{shouldShowVisaSubclass && (
						<GenericInput
							name="visaSubclass"
							register={register}
							type="number"
							label="What is your visa subclass number?"
							placeholder="Visa type"
							error={errors.visaSubclass && errors.visaSubclass.message}
						/>
					)}
					{useOrigination && (
						<SelectInput
							name="maritalStatus"
							value={maritalStatusFormValue || DEFAULT_OPTION}
							label="What is your marital status?"
							options={maritalStatusOptions}
							register={register}
							error={errors.maritalStatus && errors.maritalStatus.message}
						/>
					)}
				</div>
			}
			footer={
				<div
					className="flex items-center justify-between gap-4"
					aria-labelledby="Actions wrapper"
				>
					<Button
						text="Back"
						variant="accent"
						iconPrefix={<i className="icon-arrow rotate-180" />}
						handleClick={backToPreviousState}
					/>

					<Button
						text="Next"
						variant="primary"
						iconSuffix={<i className="icon-arrow" />}
					/>
				</div>
			}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default JointBorrowerAboutYouForm;
