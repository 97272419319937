import { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./redux-hooks";
import { useTokenLoginMutation } from "../services/api";
// import type { User } from "../services/apis/auth";
import { useAuthSuccessHandler } from "./use-auth-success";
import { setFormLoading, setIsFullScreen } from "../store/slices/loader-slice";
import { setLoginModal } from "../store/slices/ui-slice";
import {
	selectIsLoggedIn,
	setAccessToken,
	// setAuthToken,
	// setOriginationUser,
	// setUser,
} from "../store/slices/auth-slice";
import { useAuthLock } from "./use-auth-context";
import { useLDFlags } from "./use-ld-flags";
import { captureException } from "../services/sentry";
import { origination } from "../services/origination";

export const useAuth0Login = () => {
	const { auth0Login } = useLDFlags();
	const dispatch = useAppDispatch();
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const { setAuthLocked } = useAuthLock();
	const {
		// user,
		isAuthenticated,
		isLoading: isLoadingAuth0,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const [tokenLogin, { isLoading }] = useTokenLoginMutation();
	const { mutateAsync } = origination.user.userDetails.useMutation();

	const { handleSuccess, handleSuccessOrigination } = useAuthSuccessHandler();

	const loginHandler = useCallback(async (): Promise<void> => {
		let accessToken = null;
		try {
			setAuthLocked(true);
			accessToken = await getAccessTokenSilently({
				authorizationParams: {
					audience: "https://api.sucasa.com.au/",
					scope: "profile email read:current_user offline_access",
				}
			});
			dispatch(setAccessToken(accessToken));

			let originationFailed = false;
			let middlewareFailed = false;
			try {
				const result = await mutateAsync();
				handleSuccessOrigination({
					accessToken: accessToken,
					user: result,
				});
				// if a user does not have any applications, we need to throw an error
				// and allow the login process to handle an application-less login
				if (result.applications?.length <= 0) {
					throw new Error("User does not have any applications.")
				}
			} catch (error: unknown) {
				captureException(error as Error);
				originationFailed = true;
			}

			try {
				const result = await tokenLogin({ accessToken }).unwrap();
				handleSuccess({
					authToken: result.authToken,
					...result.data.content.content,
				});
			} catch (error: unknown) {
				captureException(error as Error);
				middlewareFailed = true;
			}
			if (middlewareFailed && originationFailed) {
				throw new Error("Origination and Middleware failed authentication.")
			}
		} catch (error) {
			if (accessToken) {
				// dispatch(setAuthToken(accessToken));
				dispatch(setLoginModal(true));
				// if (user) {
				// 	dispatch(setUser({ email: user?.email, fullName: user?.nickname || user?.name } as User));
				// 	dispatch(setOriginationUser({ email: user?.email, fullName: user?.nickname || user?.name } as User));
				// }
			} else {
				captureException(error as Error);
				toast.error("There was an error trying to login, please try again.");
				setTimeout(() => {
					// If the api responds with error, we logout the user to prevent further Auth0 session restarts
					void logout({ logoutParams: { returnTo: window.location.origin } });
				}, 3000);
			}
		} finally {
			dispatch(setFormLoading(false));
			setAuthLocked(false);
		}
	}, [dispatch, getAccessTokenSilently, handleSuccess, handleSuccessOrigination, logout, mutateAsync, setAuthLocked, tokenLogin, /*user*/])

	useEffect(() => {
		dispatch(setFormLoading(auth0Login && (isLoading || isLoadingAuth0)));
		dispatch(setIsFullScreen(auth0Login));
		return () => {
			dispatch(setFormLoading(false));
		};
	}, [auth0Login, dispatch, isLoading, isLoadingAuth0]);

	useEffect(() => {
		if (isAuthenticated && !isLoggedIn) {
			void loginHandler();
		}
	}, [isAuthenticated, isLoggedIn, loginHandler]);
};
