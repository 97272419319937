import type { FC } from "react";
import yourAdvisor from "../../../../assets/your-advisor.png";
// import { auLocalAreaCodeFormatter } from "../../../../lib/utils";
import type { HomeLoanSpecialist } from "../../../../services/apis/create-application.schema";
import BottomBarItem from "./bottom-bar-item/BottomBarItem";

const BottomBar: FC<HomeLoanSpecialist> = ({
	// email,
	// name,
	// phone,
	meetingLink,
}) => {
	return (
		<section aria-labelledby="Bottom bar" className="bg-primary p-5">
			<div className="container mx-auto px-4 flex items-center flex-wrap gap-4 lg:gap-0">
				<h5 className="text-white text-base font-medium lg:mr-44">
					Have a question?
				</h5>
				<div className="flex items-center flex-wrap gap-6 lg:gap-12">
					<BottomBarItem
						label="Home Loan Specialist"
						value="Lauren Bechara"
						valueType="text"
						iconPrefix={yourAdvisor}
					/>
					{/* <BottomBarItem
						label="Call"
						value={auLocalAreaCodeFormatter(phone)}
						valueType="phone"
					/> */}
					<BottomBarItem label="Email" value="applications@sucasa.com.au" valueType="email" />
					<a
						href={meetingLink}
						target="_blank"
						rel="noopener noreferrer"
						className="flex items-center gap-2 font-medium text-sm leading-6 transition-all bg-white text-primary py-2 px-4 min-w-40"
					>
						<span className="flex-grow text-start">Book a Call</span>
						<i className="icon-arrow" />
					</a>
				</div>
			</div>
		</section>
	);
};

export default BottomBar;
