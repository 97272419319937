import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import config from "../config";

export const initAnalytics = () => {
	if (config.VITE_APP_ENVIRONMENT === "production") {
		amplitude.init(config.VITE_AMPLITUDE_API_KEY, {
			defaultTracking: {
				formInteractions: false,
				fileDownloads: false,
				attribution: true,
				pageViews: true,
				sessions: true,
			},
		});
		const sessionReplay = sessionReplayPlugin({sampleRate: 1});
		amplitude.add(sessionReplay);
	}
};

export const track = (
	eventName: string,
	eventProperties?: Record<string, unknown>
) => {
	if (config.VITE_APP_ENVIRONMENT !== "production") {
		console.log("Tracking", eventName, eventProperties);
		return;
	}
	amplitude.track(eventName, eventProperties);
};

export const setUserId = (userId: string) => {
	// amplitude doesn't allow ids that smaller than 5 characters
	amplitude.setUserId(userId.length <= 4 ? `id_${userId}` : userId);
};

export const identifyApplication = ({
	appId,
	applicationType,
	applicantType,
	applicationVersion,
}: {
	appId: string;
	applicationType: string;
	applicantType: string;
	applicationVersion: string;
}) => {
	const identifyEvent = new amplitude.Identify();
	identifyEvent.setOnce("applicationId", appId);
	identifyEvent.setOnce("applicationType", applicationType);
	identifyEvent.setOnce("applicantType", applicantType);
	identifyEvent.setOnce("applicationVersion", applicationVersion);
	amplitude.identify(identifyEvent);
};
