import { useEffect, type FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/ui/loader/Loader";
import { useAuthLock } from "../hooks/use-auth-context";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { selectIsLoggedInWithApplication } from "../store/slices/auth-slice";
import { NamedRoute } from "../components/utils/NamedRoutes";
import Modal from "../components/forms/modal/Modal";
import type { ButtonType } from "../components/ui/button/Button";
import { setLoginModal } from "../store/slices/ui-slice";
import { useLogOut } from "../hooks/use-logout";

export const AuthCallback: FC = () => {
	const dispatch = useAppDispatch();
	const { isAuthLocked } = useAuthLock();
	const { clearOrRedirect } = useLogOut();
	const isLoggedInWithApplication = useAppSelector(selectIsLoggedInWithApplication);
	const showLoginModal = useAppSelector((state) => state.ui.showLoginModal);
	const isLoggedIn = isLoggedInWithApplication && !isAuthLocked;
	const navigate = useNavigate();

	const handleLogin = useCallback(() => {
		clearOrRedirect(true);
		navigate(NamedRoute.gettingStarted, { replace: true });
		dispatch(setLoginModal(false));
	}, [dispatch, navigate, clearOrRedirect]);

	const loginModalAction: Array<ButtonType> = [
		{
			text: "Start Application",
			variant: "primary",
			handleClick: handleLogin,
			type: "button",
			textAlign: "start",
			size: "fit",
			iconSuffix: <i className="icon-arrow" />,
		},
	];

	useEffect(() => {
		if (isLoggedIn) {
			navigate(NamedRoute.welcome);
		}
	}, [navigate, isLoggedIn])
	return <>
		<Loader isLoading isFullScreen />
		{showLoginModal && (
			<Modal
				isOpen={showLoginModal}
				actions={loginModalAction}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-warning text-3xl md:text-[80px]" />
					</div>
				}
				onClose={handleLogin}
				content={
					<p className="text-sm text-primary text-center">
						Welcome to Sucasa, you do not have an application yet.
						Please click "Start Application" to begin.
					</p>
				}
			/>
		)}
	</>
}
