import { useCallback, useEffect, useState, type MouseEvent } from "react";
import Button from "./Button";
import { useAuth0Token } from "../../../hooks/use-auth0-token";
import { useTracking } from "../../../hooks/use-tracking";

const COOL_DOWN_DURATION = 30; // 30s

export const ResendTokenButton = () => {
	const [coolDown, setCoolDown] = useState(0)
	const { resendToken, isLoading: isLoadingOtpResend } = useAuth0Token();
	const { trackEvent } = useTracking();

	const startCoolDown = () => {
		setCoolDown(COOL_DOWN_DURATION);
		const interval = setInterval(() => {
			setCoolDown((currentCoolDown) => {
				if (currentCoolDown <= 1) {
					clearInterval(interval);
					return 0;
				}
				return currentCoolDown - 1;
			});
		}, 1000);
	};

	  const handleClick = useCallback(async (event: MouseEvent) => {
    	event.preventDefault(); // Prevent form submission
		event.stopPropagation(); // Stop event bubbling
		
		trackEvent("Clicked Resend Code");
		await resendToken();
		startCoolDown();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resendToken]);

	useEffect(() => {
		startCoolDown();
	}, []);

	return (
		<Button
			text={coolDown > 0 ? `Resend in ${coolDown}...` : "Resend code"}
			variant="accent"
			size="full"
			handleClick={handleClick}
			isDisabled={isLoadingOtpResend || coolDown > 0}
			type="button"
		/>
	);
};
