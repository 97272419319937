/* eslint-disable camelcase */
import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";
import type { OptionsType } from "../../../../../common/types";
import { type LoanOnProperty, LoanOnPropertyType } from "../../../../../services/apis/create-application.schema";

export const AssetsSchema = z.object({
	cashOrSavingAssets: z
		.object({
			name: z.string(),
			value: z.number({
				invalid_type_error: "This field is required",
			}),
		})
		.array()
		.optional(),
	superannuationAssets: z
		.object({
			name: z.string(),
			value: z.number({
				invalid_type_error: "This field is required",
			}),
		})
		.array()
		.optional(),
	sharesEquitiesInvestmentsAssets: z
		.object({
			investmentName: z.string(),
			currentValue: z.number({
				invalid_type_error: "This field is required",
			}),
			timeOwnedMonths: z.object({
				id: z.number(),
				label: z.string(),
				value: z.string(),
			}),
			timeOwnedYears: z.object({
				id: z.number(),
				label: z.string(),
				value: z.string(),
			}),
		})
		.array()
		.optional(),
	carAssets: z
		.object({
			name: z.string(),
			year: z
				.number()
				.min(1000, "Enter a valid year")
				.max(9999, "Enter a valid year"),
			make: z.string(),
			model: z.string(),
			value: z.number({
				invalid_type_error: "This field is required",
			}),
		})
		.array()
		.optional(),
	boatAssets: z
		.object({
			name: z.string(),
			year: z.number(),
			make: z.string(),
			model: z.string(),
			value: z.number({
				invalid_type_error: "This field is required",
			}),
		})
		.array()
		.optional(),
	investmentPropertyAssets: z
		.object({
			propertyAddress: z.string(),
			monthlyRentalIncome: z.number({
				invalid_type_error: "This field is required",
			}),
			monthlyExpenses: z.number({
				invalid_type_error: "This field is required",
			}),
			primaryApplicantOwnership: z.number({
				invalid_type_error: "This field is required",
			}),
			jointApplicantOwnership: z.number({
				invalid_type_error: "This field is required",
			}),
			propertyValue: z.number(),
			noLoanOnProperty: z.boolean().optional(),
			loansOnProperty: z
				.object({
					amount: z.number({
						invalid_type_error: "This field is required",
					}).min(1, "This field is required"),
					interestRate: z.number({
						invalid_type_error: "This field is required",
					}).min(1, "This field is required"),
					monthlyRepayment: z.number({
						invalid_type_error: "This field is required",
					}).min(1, "This field is required"),
					type: z.union([
						z.literal(LoanOnPropertyType.Fixed, {
							errorMap: () => ({
								message: "Please indicate the type of loan",
							}),
						}),
						z.literal(LoanOnPropertyType.Variable, {
							errorMap: () => ({
								message: "Please indicate the type of loan",
							}),
						}),
					]),
					fixedRateExpiryDate: z.string().optional(),
					remainingTermInMonths: z.object({
						id: z.number(),
						label: z.string(),
						value: z.string(),
					}),
					remainingTermInYears: z.object({
						id: z.number(),
						label: z.string(),
						value: z.string(),
					}),
				})
				.partial()
				.array()
				.optional(),
		})
		.array()
		.optional()
		.superRefine((investmentPropertyAssets, context) => {
			if (investmentPropertyAssets && investmentPropertyAssets.length > 0) {
				investmentPropertyAssets.forEach(
					({ noLoanOnProperty, loansOnProperty }, index) => {
						if (
							(!loansOnProperty || loansOnProperty?.length === 0) &&
							noLoanOnProperty !== true
						) {
							context.addIssue({
								code: z.ZodIssueCode.custom,
								message: `Please tick the box if no loan is on the property`,
								path: [index, "noLoanOnProperty"],
							});
						}
						!noLoanOnProperty && loansOnProperty?.forEach(({
						  type,
						  amount,
						  interestRate,
						  monthlyRepayment,
						  fixedRateExpiryDate,
						  remainingTermInMonths,
						  remainingTermInYears
						}, index2) => {
							if (!type) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `Please indicate the type of loan`,
									path: [index, "loansOnProperty", index2, "type"],
								});
							}
							if (!amount) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required`,
									path: [index, "loansOnProperty", index2, "amount"],
								});
							}
							if (!interestRate) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required`,
									path: [index, "loansOnProperty", index2, "interestRate"],
								});
							}
							if (!monthlyRepayment) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required`,
									path: [index, "loansOnProperty", index2, "monthlyRepayment"],
								});
							}
							if (type === LoanOnPropertyType.Fixed && !fixedRateExpiryDate) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required if interest type is fixed`,
									path: [index, "loansOnProperty", index2, "fixedRateExpiryDate"],
								});
							}
							if (!remainingTermInMonths) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required`,
									path: [index, "loansOnProperty", index2, "remainingTermInMonths"],
								});
							}
							if (!remainingTermInYears) {
								context.addIssue({
									code: z.ZodIssueCode.custom,
									message: `This field is required`,
									path: [index, "loansOnProperty", index2, "remainingTermInYears"],
								});
							}
						});
					}
				);
			}
		}),
	otherAssets: z
		.object({
			name: z.string(),
			value: z.number({
				invalid_type_error: "This field is required",
			}),
		})
		.array()
		.optional(),
});
export type InvestmentPropertyAsset = {
	propertyAddress: string;
	monthlyRentalIncome: number;
	monthlyExpenses: number;
	primaryApplicantOwnership: number;
	jointApplicantOwnership: number;
	propertyValue: number;
	noLoanOnProperty?: boolean;
	loansOnProperty?: Array<LoanOnProperty>;
};
export type AssetsSchemaFormType = z.infer<typeof AssetsSchema>;
export type Asset = {
	name: string;
	value: number;
};
export type SharesEquitiesInvestmentsAsset = {
	investmentName: string;
	currentValue: number;
	timeOwnedMonths: OptionsType;
	timeOwnedYears: OptionsType;
};
export type VehicleAsset = {
	name: string;
	year: number;
	make: string;
	model: string;
	value: number;
};
export type AssetsFormSlice = {
	cashOrSavingAssets?: Array<Asset>;
	superannuationAssets?: Array<Asset>;
	sharesEquitiesInvestmentsAssets?: Array<SharesEquitiesInvestmentsAsset>;
	carAssets?: Array<VehicleAsset>;
	boatAssets?: Array<VehicleAsset>;
	investmentPropertyAssets?: Array<InvestmentPropertyAsset>;
	otherAssets?: Array<Asset>;
	previousFinancesActiveForm?: string;
};
export const selectAssets = createSelector(
	(state: RootState) => state.form,
	(form): AssetsFormSlice => ({
		cashOrSavingAssets: form.cashOrSavingAssets,
		superannuationAssets: form.superannuationAssets,
		sharesEquitiesInvestmentsAssets: form.sharesEquitiesInvestmentsAssets,
		carAssets: form.carAssets,
		boatAssets: form.boatAssets,
		investmentPropertyAssets: form.investmentPropertyAssets,
		otherAssets: form.otherAssets,
		previousFinancesActiveForm: form.previousFinancesActiveForm,
	})
);
