import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
	BorrowerStage,
	LoanPurpose,
	type CreateApplicationRequest,
	Step,
	SubStep,
	PropertyType,
} from "../../services/apis/create-application.schema";
import { getMarketingCookies } from "../../lib/utils";

export const selectPrimaryApplicationDetails = (form: RootState["form"]) => ({
	title: form.title?.value,
	firstName: form.firstName,
	middleName: form.middleName,
	surname: form.surname,
	maritalStatus: form.maritalStatus?.value,
	marriedToApplicantOnApplication: form.isRelationStatus === "true",
});

export const selectApplicationPayload = createSelector(
	[(state: RootState) => state.form],
	(form) => {
		const payload: Partial<CreateApplicationRequest> = {
			applicationStep: Step.YourDetails,
			applicationSubStep: SubStep.FineDetailsAboutYou,
			primaryBorrowerPassword: form.password,
			primaryBorrowerPasswordConfirmation: form.confirmPassword,
			creditCheckPreference: form.creditCheckPreference,
			purpose: form.loanPurpose,
			stage:
				form.borrowerStage?.value === "select_option"
					? BorrowerStage.Under3months
					: (form.borrowerStage?.value as BorrowerStage),
			propertyType:
				form.isVacantLandOrHomeAndLand === "yes" ? PropertyType.VacantLand : undefined,
			isVacantLandOrHomeAndLand: form.isVacantLandOrHomeAndLand === "yes",
			addressParts: {
				postalCode: form.propertyAddressParts?.postalCode ?? "",
				suburb: form.propertyAddressParts?.suburb ?? "",
				state: form.propertyAddressParts?.state ?? "",
				streetNumber: form.propertyAddressParts?.streetNumber,
				streetName: form.propertyAddressParts?.streetName,
				unitNumber: form.propertyAddressParts?.unitNumber,
			},
			//@ts-expect-error TODO
			reason: form.propertyPurpose,
			firstHomeBuyer: form.firstHomeBuyer === "yes",
			purchasePrice: form.loanPurchasePrice,
			borrowAmount: form.loanBorrowAmount ?? 0,
			savings: form.loanSavings,
			giftedAmount: form.loanGiftedAmount,
			giftedAmountGiftedBy: form.loanGiftedBy,
			//@ts-expect-error TODO
			loanPreference: form.loanPreference?.value,
			loanTermInYears: form.loanTermInYears,
			meetsNeedsAndObjectives: form.meetsNeedsAndObjectives === "true",
			requestedLoanFeatures:
				form.requestedLoanBuyingFeatures ||
				form.requestedLoanRefinancingFeatures,
			//@ts-expect-error TODO
			applicationType: form.applicationType ?? "Single",
			//@ts-expect-error TODO
			primaryApplicationDetails: selectPrimaryApplicationDetails(form),
			numberOfDependants: form.numberOfDependants,
			dependantAges: form.dependantAges,
			residencyStatus: form.residencyStatus?.value,
			visaSubclassNumber:
				form.visaSubclass && /^\d{3}$/.test(form.visaSubclass.toString())
					? form.visaSubclass.toString()
					: undefined,
			email: form.email,
			mobile: form.mobile,
			marketingConsent: form.marketingConsent,
			privacyConsent: form.privacyConsent,
			propertyValuation: form.propertyValuation,
			// API is failing without this
			refinanceLoanNewLoanHasSameBorrowersAsExistingLoan: false,
		};

		if (form.loanPurpose !== LoanPurpose.Purchase) {
			payload.refinanceLoanCurrentLender = form.currentLender;
			payload.refinanceLoanCurrentRemainingTermInMonths =
				Number(form.remainingTermInMonths?.value) +
				Number(form.remainingTermInYears?.value) * 12;
			payload.refinanceLoanCurrentBorrowers = Number(form.existingBorrowers);
			//@ts-expect-error TODO
			payload.loansOnProperty = form.loansOnProperty;
			payload.refinanceLoanAdditionalBorrowingAmount =
				form.additionalBorrowingAmount;
			payload.refinanceLoanReasonForAdditionalFunds =
				form.additionalFundsReason;
			payload.refinanceLoanNewLoanHasSameBorrowersAsExistingLoan =
				form.sameBorrowersAsExistingLoan;
			payload.reasonsForRefinancing = form.requestedLoanRefinancingFeatures;
			payload.otherReasonForRefinancing =
				form.requestedLoanRefinancingFeaturesOther;
			payload.otherRequestLoanFeature = form.requestedLoanBuyingFeaturesOther;
		}

		Object.entries(getMarketingCookies()).forEach(([key, value]) => {
			if (value) {
				payload[key as keyof ReturnType<typeof getMarketingCookies>] = value;
			}
		});
		// Filter out undefined, null, or "" values
		Object.keys(payload).forEach((key) => {
			const payloadKey = key as keyof typeof payload;
			if (
				payload[payloadKey] === undefined ||
				payload[payloadKey] === null ||
				payload[payloadKey] === "" ||
				Number.isNaN(payload[payloadKey])
			) {
				delete payload[payloadKey];
			}
		});

		return payload as CreateApplicationRequest;
	}
);
