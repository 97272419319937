import { useMutation } from "@tanstack/react-query";
import { legacy } from "../../api";
import { origination } from "../../origination";
import { mapCreateApplicationRequest, mapCreateApplicationResponse } from "./mapping/application";
import { mapUpdateApplicantCircumstancesResponse, mapUpdateApplicantDetailsResponse, mapUpdateApplicationCircumstancesRequest, useMapUpdateApplicantCircumstancesRequest, useMapUpdateApplicantDetailsRequest } from "./mapping/details";
import { useOriginationCallback, useOriginationMutationResult } from "./mutation";
import type { UpdateCircumstancesRequest } from "../update-circumstances.schema";
import { useCallback } from "react";
import { selectFormApplicationId } from "../../../store/slices/application-slice";
import { selectCurrentOriginationUser } from "../../../store/slices/auth-slice";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectUseOrigination } from "../../../store/slices/origination-slice";

export const useSubmitApplication = () => {
    const [invokeOriginal, originalMutation] = legacy.useCreateApplicationV2Mutation();
    const mutation = origination.application.createApplication.useMutation();
    const create = useOriginationCallback(
      invokeOriginal,
      mutation.mutateAsync,
      mapCreateApplicationRequest,
      mapCreateApplicationResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}


export const useUpdateApplicantDetails = () => {
    const [invokeOriginal, originalMutation] = legacy.useUpdateDetailsMutation();
    const utils = origination.useUtils();
    const mutation = origination.application.updateUserDetails.useMutation({
        onSuccess(_, variables) {
            return utils.application.getApplicationById.invalidate({
                applicationId: variables.applicationId
            });
        }
    });
    const mapRequest = useMapUpdateApplicantDetailsRequest();
    const create = useOriginationCallback(
      invokeOriginal,
      mutation.mutateAsync,
      mapRequest,
      mapUpdateApplicantDetailsResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}

const useUpdateApplicantCircumstances = () => {
    const [invokeOriginal, originalMutation] = legacy.useUpdateCircumstancesMutation();
    const utils = origination.useUtils();
    const mutation = origination.application.updateUserDetails.useMutation({
        onSuccess(_, variables) {
            return utils.application.getApplicationById.invalidate({
                applicationId: variables.applicationId
            });
        }
    });
    const mapRequest = useMapUpdateApplicantCircumstancesRequest();
    const create = useOriginationCallback(
      invokeOriginal,
      mutation.mutateAsync,
      mapRequest,
      mapUpdateApplicantCircumstancesResponse
    )
    const mutationResult = useOriginationMutationResult(originalMutation, mutation);
    return [
        create,
        mutationResult
    ] as const;
}

export const useUpdateApplicationCircumstances = (isJoint = false) => {
    const applicationId = useAppSelector(selectFormApplicationId);
    const user = useAppSelector(selectCurrentOriginationUser);
    const utils = origination.useUtils();
    const useOrigination = useAppSelector(selectUseOrigination);
    const [invokeBase, baseMutation] = useUpdateApplicantCircumstances();
    const applicationMutation = origination.application.updateApplication.useMutation({
        onSuccess(_, variables) {
            return utils.application.getApplicationById.invalidate({
                applicationId: variables.id
            });
        }
    });
    const mutation = useMutation({
        mutationKey: [applicationId, user?.userId],
        async mutationFn(request: UpdateCircumstancesRequest) {
            // The base invoke updates the applicant
            // If we're using the new origination services we
            // need to update the application separately.
            //
            // In the old API, everything was on the applicant only.
            const response = await invokeBase(request).unwrap();
            if (!useOrigination || isJoint) {
                return response;
            }
            // don't update application for joint applicant
            const update = mapUpdateApplicationCircumstancesRequest(request, applicationId);
            await applicationMutation.mutateAsync(update);
            return response;
        }
    })
    const { mutateAsync } = mutation;
    const wrappedCallback = useCallback((request: UpdateCircumstancesRequest) => {
        const promise = mutateAsync(request);
        return {
            unwrap() {
                return promise;
            }
        }
    }, [mutateAsync])
    const mergedMutation = useOriginationMutationResult(baseMutation, mutation);
    return [wrappedCallback, mergedMutation] as const;
}
