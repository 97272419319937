import { useMemo, type FC } from "react";
import { skipToken } from "@tanstack/react-query";
import { VerificationStatus } from "@sucasa-finance/origination-trpc";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import type { JointStepsType, StepsType } from "../Stepper.config";
import { CheckGreen } from "../../../ui/check/CheckGreen";
import { selectFormApplicationId } from "../../../../store/slices/application-slice";
import { origination } from "../../../../services/origination";
import { selectUseOrigination } from "../../../../store/slices/origination-slice";

const LABELS_TO_IGNORE_ACTION = new Set([
	'Your Home',
	'Your Loan',
	'Your Details',
	'Your Finances'
])

type StepperItemProps = {
	item: StepsType | JointStepsType;
	isJoint: boolean;
	isCurrent?: boolean;
	isCompleted?: boolean;
	isLastItem?: boolean;
}
export const StepperItem: FC<StepperItemProps> = ({
	item,
	isJoint,
	isCurrent,
	isCompleted,
	isLastItem
}) => {
	const isOrigination = useAppSelector(selectUseOrigination);
	const stepperState = useAppSelector((state) =>
		isJoint ? state.jointStepper : state.stepper
	);
	const applicationId = useAppSelector(selectFormApplicationId);
	const query = origination.verification.getCases.useQuery(applicationId ? {applicationId: Number(applicationId)} : skipToken)

	const activeCases = useMemo(() => query.data?.filter(itm =>
		[VerificationStatus.Active].includes(itm.status)) || [], [query.data]);

	const actionIsRequired = activeCases.length > 0;
	const derivedCurrent =
		isCurrent ?? stepperState.steps?.[item.value]?.isCurrent;
	const derivedCompleted =
		isCompleted ?? stepperState.steps?.[item.value]?.isCompleted;

	return (
		<>
			<span className="flex items-center justify-center">
				{derivedCompleted ? (
					<CheckGreen />
				) : (
					<span
						className={`${derivedCurrent ? "bg-primary text-white" : "border-2 border-off-black-200 text-off-black-600 app-bg-static"}  w-8 h-8 rounded-full flex items-center justify-center text-sm leading-6 font-medium`}
					>
						{item.id}
					</span>
				)}
			</span>
			<span
				className={`flex flex-col ${derivedCurrent ? "font-medium" : " text-off-black-600"} ${derivedCompleted ? "text-primary" : ""}`}
			>
				<span>{item.label}</span>
			</span>
			<span className="flex justify-center relative min-h-16">
				{!isLastItem && (
					<span
						className={`absolute w-0.5 top-[-3px] h-[calc(100%+7px)] ${derivedCompleted ? "bg-grass-hopper-900" : "bg-off-black-200"} block -z-10`}
					/>
				)}
			</span>
			<span className="flex flex-col gap-y-2">
				{/* TODO(janyk): fix this */}
				{isOrigination && derivedCurrent && !LABELS_TO_IGNORE_ACTION.has(item.label) && actionIsRequired && <span className="text-off-black-600 text-sm"><i className="icon-progress text-red-brick-100" /> Action required</span>}
				{isOrigination && derivedCurrent && !LABELS_TO_IGNORE_ACTION.has(item.label) && !actionIsRequired && <span className="text-off-black-600 text-sm"><i className="icon-progress text-[#9876CA]" /> Waiting on us</span>}
			</span>
		</>
	);
};
